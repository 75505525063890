@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;

.sc7-richtext {
    @extend %font-paragraph;

    &--dsksmall {
        @include respond-above(l) {
            margin-left: auto;
            margin-right: auto;
            max-width: rem(720px);
        }
    }

    b,
    strong {
        font-weight: bold;
    }
    u {
        text-decoration: underline;
    }
    i {
        font-style: italic;
    }
    a,
    span[data-modal] {
        //style for links and modals
        cursor: pointer;
        text-decoration: underline;
    }

    a {
        color: var(--color-dark-yellow);
    }

    //default font size
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: rem(8px);

        &:last-child {
            margin-bottom: 0;
        }
    }

    p {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .font-txt {
        @extend %font-paragraph;
    }

    .font-18Fix {
        @extend %font-18Fix;
    }

    .font-20Fix {
        @extend %font-20Fix;
    }

    .font-20Resp {
        @extend %font-20Resp;
    }

    .font-24Resp {
        @extend %font-24Resp;
    }

    .font-26Resp {
        @extend %font-26Resp;
    }

    .font-32Resp {
        @extend %font-32Resp;
    }

    .font-32Resp24 {
        @extend %font-32Resp24;
    }

    .font-48Resp {
        @extend %font-48Resp;
    }

    .font-120Resp {
        @extend %font-120Resp;
    }

    .color-alt {
        //color: $txtAlt;
    }

    .color-white {
        color: var(--color-white);
    }

    .color-darkGrey {
        color: var(--color-steamy-smoke);
    }

    .color-black {
        color: var(--color-black);
    }

    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: rem(40px);
        unicode-bidi: isolate;

        margin-bottom: 1rem;
        &:last-child {
            margin-bottom: 0;
        }
    }

    h1 {
        font-size: 2.5rem;
    }

    h2 {
        font-size: 2rem;
    }

    h3 {
        font-size: 1.75rem;
    }

    h4 {
        font-size: 1.5rem;
    }

    h5 {
        font-size: 1.25rem;
    }

    h6 {
        font-size: 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
    }
}
