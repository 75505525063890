@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.st4-article-card {
    width: rem(160px);
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;

    @include respond-above(m) {
        width: rem(352px);
    }

    &__media {
        width: 100%;
        height: rem(111px);

        @include respond-above(m) {
            height: rem(240px);
        }
    }

    &__content {
        margin-top: rem(17px);
        padding-right: rem(15px);
        padding-left: rem(15px);
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }

    &__texts {
        flex: 1 1 auto;
        color: var(--color-black);
    }

    &__title {
        @extend %font-h6;
        font-weight: 600;
        text-align: left;
        color: var(--color-black);
    }

    &__description {
        @extend %font-description;
        display: none;
        font-weight: normal;
        text-align: left;
        line-height: 1.54;
        letter-spacing: 0.19px;
        color: var(--color-brillant-grey);
        margin-bottom: 0;

        @include respond-above(l) {
            display: block;
        }
    }

    &__breadcrumb {
        @extend %font-label;
        font-weight: 500;
        color: var(--color-black);
    }

    &__label {
        @extend %font-label;
        margin: 0 rem(10px);
        font-weight: normal;
        text-align: center;
        white-space: nowrap;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include respond-above(l) {
            flex-direction: row;
        }
    }

    &__infos {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__icon {
        width: rem(19px);
        height: rem(19px);
    }

    &__separator {
        width: 100%;
        height: rem(1px);
        margin-top: rem(21px);
        margin-bottom: rem(22px);
        opacity: 0.16;
        background-color: var(--color-brown);
    }

    &__bottom {
        display: flex;
        flex-direction: row;
        margin-bottom: rem(26px);
        justify-content: flex-end;

        @include respond-above(l) {
            justify-content: space-between;
            align-items: center;
        }
    }

    &__appr {
        @extend %font-label-v2;
        font-weight: 600;
        text-decoration: underline;
        display: none;

        a {
            color: #974D44;
        }

        @include respond-above(l) {
            display: block;
        }
    }

    &__ctas {
        display: flex;
        flex-direction: row;
    }

    @include respond-below(m) {
        &--mobileLarge {
            width: rem(300px);
            height: 100%;
        }
        &--mobileLarge &__media-container {
            height: rem(197px);
        }
        &--mobileLarge &__media {
            width: 100%;
            height: 100%;
        }
        &--mobileLarge &__description {
            display: block;
        }
        &--mobileLarge &__item {
            flex-direction: row;
        }
        &--mobileLarge &__label {
            margin: 0 rem(8px);
        }
        &--mobileLarge &__appr {
            display: block;
        }
        &--mobileLarge &__bottom {
            justify-content: space-between;
        }
    }
}
