@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;


.sc15-inspiration {
    @extend %container;
    
    &__title {
        @extend %font-h3;
        font-size: rem(24px);
        font-weight: 600;
        max-width: rem(358px);

        @include respond-above(l) {
            max-width: rem(720px);
        }
    }

    &__subtitle {
        @extend %font-display;
        line-height: rem(30px);
        font-size: rem(32px);
        max-width: rem(310px);
        margin-bottom: rem(50px);
        background-color: alpha(--color-dark-yellow, 0.28);
        padding: rem(2px);
        @include respond-above(m) {
            max-width: rem(592px);
        }
    }

    &__slider {

        .st6-slider__btn {
            &--prev {
                left: rem(-57px);
            }
            &--next {
                right: rem(-57px);
            }
        }

        .st6-slider__pagination{
            display: flex;

            @include respond-above(l) {
                display: none;
            }
        }
        .st6-slider__track {
            margin-left: 0;
        }
    }
}
