@use '../../../../../../../style/modules/functions' as *;
@use '../../../../../../../style/modules/commons' as *;
@use '../../../../../../../style/modules/variables' as *;
@use '../../../../../../../style/modules/mixins' as *;

.akt-recipes-author {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: rem(14px);

    @include respond-above(l) {
        margin-top: rem(23px);
    }

    a {
        text-decoration: underline;
    }

    &__avatar {
        margin-right: rem(11px);
        width: rem(32px);
        height: rem(32px);
    }

    &__image {
        border-radius: 50%;
        object-fit: cover;
    }

    &__info {

        &--prefix {
            display: block;
            @extend %font-display;
            font-size: rem(20px);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: rem(23px);
            letter-spacing: normal;
            margin-bottom: 0;
        }

        &--fullName {
            margin-bottom: 0;
        }

        &--fullNameText {
            display: block;
            
            font-size: rem(14px);
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: rem(18px);
            letter-spacing: normal;
            color: #000000;
            margin-bottom: 0;
        }
    }
}