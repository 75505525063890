@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;


.sc16-travels {
    position: relative;
    @extend %container;

    &__texts {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: rem(29px);
    }

    &__innerContent {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include respond-above(l) {
            flex-direction: row;
        }
    }

    &__artContainer {
        width: 100%;
        height: rem(380px);
        position: relative;
        @include respond-above(l) {
            width: 100%;
            height: rem(432px);
        }
        
        &--media {
            height: 23.75rem;
    
            @include respond-above(l) {
                width: 100%;
                height: rem(432px);
            }
        }

        &--cardTitle {
            @extend %font-h4;
            min-height: rem(33px);
            margin-bottom: 0;
            position: absolute;
            bottom: rem(47px);
            right: rem(142px);
            left: rem(32px);
            line-height: 1.7;

            & > a {
                & > h3 {
                    color: var(--color-black);
                    @extend %font-h4;
                    line-height: 1.7;
                    font-weight: 600;
                    display: inline;
                    background: alpha(--color-white, 0.8);
                    box-shadow: 10px 0 0 alpha(--color-white, 0.8), -10px 0 0 alpha(--color-white, 0.8);
                    @include respond-above(l) {
                        font-size: rem(24px);
                    }
                }
            }

            @include respond-above(l) {
                bottom: rem(47px);
                right: rem(142px);
                font-size: rem(24px);
            }
        }

        &--chips{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            position: absolute;
            top: rem(32px);
            left: rem(32px);
            bottom: rem(434px);
            right: auto;
        }
    
        &--chip {
            min-height: rem(25px);
            margin: rem(4px) rem(7px) rem(4px) rem(5px);
            padding: rem(2px) rem(17px) rem(8px )rem(16px);
            border-radius: rem(16px);
            border: solid 2px var(--color-salmon-rose);
            background-color: var(--color-white);
        }
    
        &--chipText {
            @extend %font-label;
            color: var(--color-black);
            text-decoration: none;
            display: flex;
        }
    
        &--ctas {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: absolute;
            top: rem(28px);
            right: rem(4px);
            left: auto;
            bottom: auto;
            
            @include respond-above(l){
                flex-direction: row;
    
            }
        }
    }
    
    &__title {
        @extend %font-h3;
        font-size: rem(24px);
        font-weight: 600;
        max-width: rem(358px);

        @include respond-above(m) {
            max-width: rem(720px);
        }
    }

    &__description {
        @extend %font-display;
        line-height: rem(30px);
        font-size: rem(32px);
        max-width: rem(310px);
        background-color: alpha(--color-dark-yellow, 0.28);
        padding: 0.4rem;
        @include respond-above(m) {
            max-width: rem(720px);
        }
    }


    &__cards {
        display: flex;
        flex-direction: column;
        margin-top: rem(30px);
        @include respond-above(l) {
            margin-top: 0;
            margin-left: rem(20px);
        }
    }

    &__card {
        @extend %container;
        background-color: var(--color-white);
        width: 100%;
        height: rem(225px);
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        justify-content: space-evenly;

        @include respond-above(l) {
            width: rem(444px);
            height: rem(203px);
        }

        &--last {
            margin-top: rem(25px);
        }
        
    }
    
    &__cardsBreadcrumb {
        @extend %font-label;
        font-weight: 500;
    }

    &__cardsTitle {
        @extend %font-paragraph;
        font-size: 16px;
        font-weight: 600;
        width: rem(290px);
        color: var(--color-black);

        @include respond-above(l) {
            width: rem(383px);
        }

    }

    &__cardsDescription {
        @extend %font-paragraph;
        font-weight: 500;
        text-align: left;
        color: var(--color-brillant-grey);
    }

    &__cardsBottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include respond-above(l) {
            justify-content: space-between;
            align-items: center;
        }
    }

    &__cardsAppr {
        @extend %font-label;
        font-weight: 600;
        text-decoration: underline;
        color: var(--color-brown-red);

        a {
            color: var(--color-brown-red);
        }
    }
    
    &__cardsCtas {
        display: flex;
        flex-direction: row;
    }
}
