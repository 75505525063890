@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.st21-poi-info {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    &__goto, &__location {
        display: flex;
        flex-direction: column;

        @include respond-above(l) {
            flex-direction: row;
        }
    }

   &__gotoSiteLabel, &__locationLabel {
        @extend %font-label;
        font-weight: 500;
        text-decoration: underline;
        text-decoration-thickness: rem(1px);
        color: var(--color-black);
   }

   &__gotoSiteLabel {
       margin-right: rem(44px);
   }

   &__gotoSiteIcon, &__locationIcon {
        width: rem(19px);
        height: rem(20px);
        margin-right: rem(6px);
   }

   &__gotoSiteIcon {
    background-image: url(../../../../../resources/saporie/images/icona-gotosite.svg);
   }

   &__locationIcon {
    background-image: url(../../../../../resources/saporie/images/icona-indicazioni.svg);
   }

}
