@use '../../../../../../../style/modules/functions' as *;
@use '../../../../../../../style/modules/commons' as *;
@use '../../../../../../../style/modules/variables' as *;
@use '../../../../../../../style/modules/mixins' as *;

.akt-recipes-summary {
    margin-top: 30px;

    &__list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        @include respond-above(l) {
            justify-content: flex-start;
        }
    }

    &__item {
        text-align: center;
        margin-bottom: 10px;

        &:not(:last-child) {
            margin-right: 25px;
            @include respond-above(l) {
                margin-right: 45px;
            }
        }
    }

    &__label {
        
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 18px;
        letter-spacing: normal;
    }
}