@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.su001-popup {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: setZ(modal);
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__content {
        position: relative;
        background-color: var(--color-white);
        opacity: 0;
        margin: 0 auto;
        max-height: 100%;
        max-width: calc(100% - 20px);

        &--animate {
            animation: zoomIn 0.5s;
        }
    }

    &__backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: alpha(--color-ground-brown, 0.75);
        display: block;
        z-index: setZ(negative);
        opacity: 0;

        &--unclosable {
            pointer-events: none;
        }
    }

    &--open {
        pointer-events: all;
    }

    &--open &__content {
        opacity: 1;
    }

    &--open &__rightContent {
        @include respond-above(l) {
            display: block;
        }

        &--forceShow {
            display: block;
        }
    }

    &--open &__backdrop {
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
    }

    &--noTransition &__backdrop {
        transition: none;
    }

    &--noTransition &__content {
        transition: none;
    }
}
