@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.st17-dish-card {
    

    &__container {
        display: flex;
        flex-direction: column;
    }
    
    &__pic {
        width: rem(259px);
        height: rem(119px);

        @include respond-above(l) {
            width: rem(352px);
            height: rem(145px);
        }
    }

    &__title {
        @extend %font-h3;
        font-size: rem(20px);
        width: rem(259px);
        padding-top: rem(11px);
        padding-left: rem(10px);
        color: var(--color-black);
        
        @include respond-above(l) {
            font-size: rem(24px);
        }
    }
}