@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;


.sc14-hero {
    &__slider {
        position: relative;
        .st6-slider__slideContainer {
            display: block;
        }

        .st6-slider__bullet {
            background-color: alpha(--color-white, 0.5);
    
            &--active {
                background-color: var(--color-white);
            }
        }

        .st6-slider__pagination {
            position: absolute;
            bottom: rem(20px);
            right: rem(26px);

            @include respond-above(l) {
                bottom: rem(40px);
                right: rem(40px);
            }
        }

        .st6-slider__btn {

            &--prev {
                left: rem(40px);
            }

            &--next {
                right: rem(40px);
            }

            &::after {
                background-image: url(../../../../../../resources/saporie/images/white_left_arrow.svg);
                width: rem(17px);
                height: rem(34px);
            }
        }
    }
}
