@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;


.sc10-card-carousel-collection {
    @extend %container;

    &__title {
        @extend %font-h3;
        font-weight: 600;
    }
    
    &__slider { 
        .st6-slider__pagination{
            display: flex;

            @include respond-above(l) {
                display: none;
            }
        }
    }

}
