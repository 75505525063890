@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.st3-loader {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: setZ(popup);
    width: 100%;
    height: 100%;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    &--show {
        display: flex;
    }

    &__backdrop {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: alpha(--color-black, 0.6);
    }

    &__loader {
        display: inline-block;
        position: relative;
        width: rem(80px);
        height: rem(80px);
    }

    &__loader div {
        position: absolute;
        border: 4px solid var(--color-black);
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

        &:nth-child(2) {
            animation-delay: -0.5s;
        }
    }
}
