@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;


.sc3-widget-recipe {
    @extend %container;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    @include respond-above(l) {
        flex-direction: row;
    }

    &__recipe {
        width: 100%;
        height: 23.75rem;

        @include respond-above(l) {
            width: 100%;
            height: 31.125rem;
        }
    }

    &__texts {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        @include respond-above(l) {
            margin-right: 6.8125rem;
        }
    }

    &__title {
        @extend %font-ced-h3;
        font-size: rem(35px);
        font-weight: normal;
        min-height: rem(160px);
        width: rem(310px);

        @include respond-above(l) {

        }
    }

    &__description {
        @extend %font-paragraph;
        width: rem(310px);
        min-height: rem(160px);

        @include respond-above(l) {

        }
    }

}
