@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;


.ss5-article {

    &__title {
        @extend %headingReset;
        font-weight: 600;
        @extend %font-h1Resp;
        margin-bottom: rem(14px);
        display: block;
        margin: rem(21px) rem(48px) 0 0;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-bottom: 0;

        @include respond-above(l) {
            margin: 0 0 0 0;
            font-size: rem(48px);
            line-height: rem(48px);
            width: 80%;
        }
    }

    &__image, &__video {
        height: rem(211px);
        margin-top: rem(23px);
        margin-bottom: rem(20px);

        @include respond-above(l) {
            height: rem(500px);
        }
    }

    &__top {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @extend %container;

        @include respond-above(l) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__anchors {
        margin-bottom: rem(70px);
    }
    
    &__ctas {
        display: flex;
        flex-direction: row;
    }

}
