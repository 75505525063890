@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;


.sp2-recipes {
    position: relative;
    max-height: 100%;
    height: 100%;
    width: calc(100% - 40px);

    @include respond-above(m) {
        width: rem(720px);
    }

    @include respond-above(l) {
        height: rem(704px);
    }

    overflow-y: hidden;
    @include adjustPerfectScrollbar();
    .ps__rail-y {
        margin-top: rem(16px);
        margin-bottom: rem(16px);
        margin-right: rem(4px);
    }

    &__close {
        @extend %buttonReset;
        position: absolute;
        right: rem(23px);
        top: rem(27px);
        font-size: rem(20px);
    }

    &__content {
        padding: rem(66px) rem(29px) rem(31px) rem(23px);

        @include respond-above(l) {
            padding: rem(75px) rem(42px) rem(75px) rem(36px);
        }
    }

    &__title {
        
        @extend %font-h5;
        font-weight: 600;
        margin: 0;
        text-align: left;
    }

    &__separator {
        border-top: 1px solid var(--color-salmon-rose);
        margin-top: rem(15px);
        margin-bottom: rem(23px);
    }

    &__accHeading {
        margin-top: rem(23px);

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__accTitle {
        @extend %font-display;
        @extend %font-h3;
        pointer-events: none;
    }

    &__arrow {
        display: inline-block;
        content: url('../../../../../../resources/saporie/images/down_arrow_black.svg');
        width: 1rem;
        height: rem(8px);
        transition: transform 0.25s ease-in-out;
        padding-left: 0.3rem;
        pointer-events: none;
    }

    &__accordion--active &__arrow {
        transform: rotate(-180deg);
    }

    &__slider {
        padding-top: rem(21px);
    }

    &__accordion &__accContent {
        display: none;
    }

    &__accordion--active &__accContent {
        display: block;
    }

    &__separatorTwo {
        border-top: 1px solid var(--color-dark-yellow);
        margin: 0;
        margin-top: rem(13px);
    }
}
