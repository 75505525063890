@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.st23-map-marker {
    max-width: 200px;
    position: relative;
    height: 42px;
    width: 32px;

    &__pin {
        background-image: url('../../../../../resources/saporie/images/icona-indicazioni.svg');
        background-repeat: no-repeat;
        background-size: 32px;
        height: 42px;
        cursor: pointer;
    }

    &__popup {
        position: absolute;
        top: rem(45px);
        left: rem(-234px);
        display: none;
        pointer-events: all;
        width: rem(342px);
        height: rem(180px);
        background: var(--color-brown);
        box-shadow: 0 8px 8px -4px var(--color-brown);
        z-index: 1;

        @include respond-below(l) {
            display: none !important;
            pointer-events: none !important;
        }

        &--show {
            display: block;
            cursor: default;
        }
    }

    &__popup-anchor {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        display: block;
    }

    &__popup-anchor::after {
        content: "";
        position: absolute;
        /* Center the tip horizontally. */
        bottom: rem(180px);
        left: rem(240px);
        transform: rotate(180deg);
        /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
        width: 0;
        height: 0;
        /* The tip is 11px high, and 18px wide. */
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-top: 11px solid var(--color-white);
        filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.2));
    }

    &--selected &__pin {
        background-image: url('../../../../../resources/saporie/images/icona-indicazioni-black.svg');
    }
}