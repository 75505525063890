@use '../modules/variables' as *;
@use '../modules/commons' as *;

@font-face {
    font-family: '#{$icomoon-font-family}';
    src: url('#{$resourcesFolder}/saporie/fonts/saporie-icons/#{$icomoon-font-family}.eot?5x1nwu');
    src: url('#{$resourcesFolder}/saporie/fonts/saporie-icons/#{$icomoon-font-family}.eot?5x1nwu#iefix')
            format('embedded-opentype'),
        url('#{$resourcesFolder}/saporie/fonts/saporie-icons/#{$icomoon-font-family}.ttf?5x1nwu') format('truetype'),
        url('#{$resourcesFolder}/saporie/fonts/saporie-icons/#{$icomoon-font-family}.woff?5x1nwu') format('woff'),
        url('#{$resourcesFolder}/saporie/fonts/saporie-icons/#{$icomoon-font-family}.svg?5x1nwu##{$icomoon-font-family}')
            format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='sc-icon-'],
[class*=' sc-icon-'] {
    @extend %sc-icon-base;
}

.sc-icon-fb .path1 {
    &:before {
        content: $sc-icon-fb-path1;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-fb .path2 {
    &:before {
        content: $sc-icon-fb-path2;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}
.sc-icon-google .path1 {
    &:before {
        content: $sc-icon-google-path1;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-google .path2 {
    &:before {
        content: $sc-icon-google-path2;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}
.sc-icon-google .path3 {
    &:before {
        content: $sc-icon-google-path3;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}
.sc-icon-instagram .path1 {
    &:before {
        content: $sc-icon-instagram-path1;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-instagram .path2 {
    &:before {
        content: $sc-icon-instagram-path2;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}
.sc-icon-pinterest .path1 {
    &:before {
        content: $sc-icon-pinterest-path1;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-pinterest .path2 {
    &:before {
        content: $sc-icon-pinterest-path2;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}
.sc-icon-twitter .path1 {
    &:before {
        content: $sc-icon-twitter-path1;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-twitter .path2 {
    &:before {
        content: $sc-icon-twitter-path2;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}
.sc-icon-youtube .path1 {
    &:before {
        content: $sc-icon-youtube-path1;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-youtube .path2 {
    &:before {
        content: $sc-icon-youtube-path2;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}
.sc-icon-alert {
    &:before {
        content: $sc-icon-alert;
    }
}
.sc-icon-autumn {
    &:before {
        content: $sc-icon-autumn;
    }
}
.sc-icon-calorie {
    &:before {
        content: $sc-icon-calorie;
    }
}
.sc-icon-cancel {
    &:before {
        content: $sc-icon-cancel;
    }
}
.sc-icon-casserole {
    &:before {
        content: $sc-icon-casserole;
    }
}
.sc-icon-chef {
    &:before {
        content: $sc-icon-chef;
    }
}
.sc-icon-fruit {
    &:before {
        content: $sc-icon-fruit;
    }
}
.sc-icon-groceries {
    &:before {
        content: $sc-icon-groceries;
    }
}
.sc-icon-italy {
    &:before {
        content: $sc-icon-italy;
    }
}
.sc-icon-oven {
    &:before {
        content: $sc-icon-oven;
    }
}
.sc-icon-party {
    &:before {
        content: $sc-icon-party;
    }
}
.sc-icon-pasta {
    &:before {
        content: $sc-icon-pasta;
    }
}
.sc-icon-people {
    &:before {
        content: $sc-icon-people;
    }
}
.sc-icon-plate {
    &:before {
        content: $sc-icon-plate;
    }
}
.sc-icon-search {
    &:before {
        content: $sc-icon-search;
    }
}
.sc-icon-see {
    &:before {
        content: $sc-icon-see;
    }
}
.sc-icon-see_cross {
    &:before {
        content: $sc-icon-see_cross;
    }
}
.sc-icon-spring {
    &:before {
        content: $sc-icon-spring;
    }
}
.sc-icon-summer {
    &:before {
        content: $sc-icon-summer;
    }
}
.sc-icon-tick {
    &:before {
        content: $sc-icon-tick;
    }
}
.sc-icon-time {
    &:before {
        content: $sc-icon-time;
    }
}
.sc-icon-user {
    &:before {
        content: $sc-icon-user;
    }
}
.sc-icon-video {
    &:before {
        content: $sc-icon-video;
    }
}
.sc-icon-winter {
    &:before {
        content: $sc-icon-winter;
    }
}
.sc-icon-bookmark .path1 {
    &:before {
        content: $sc-icon-bookmark-path1;
        color: #f3b136;
    }
}
.sc-icon-bookmark .path2 {
    &:before {
        content: $sc-icon-bookmark-path2;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}
.sc-icon-bookmark_light .path1 {
    &:before {
        content: $sc-icon-bookmark_light-path1;
        color: #fdf3df;
    }
}
.sc-icon-bookmark_light .path2 {
    &:before {
        content: $sc-icon-bookmark_light-path2;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}
.sc-icon-gallery .path1 {
    &:before {
        content: $sc-icon-gallery-path1;
        color: rgb(247, 194, 96);
    }
}
.sc-icon-gallery .path2 {
    &:before {
        content: $sc-icon-gallery-path2;
        margin-left: -1em;
        color: rgb(247, 194, 96);
    }
}
.sc-icon-gallery .path3 {
    &:before {
        content: $sc-icon-gallery-path3;
        margin-left: -1em;
        color: rgb(247, 194, 96);
    }
}
.sc-icon-gallery .path4 {
    &:before {
        content: $sc-icon-gallery-path4;
        margin-left: -1em;
        color: rgb(247, 194, 96);
    }
}
.sc-icon-gallery .path5 {
    &:before {
        content: $sc-icon-gallery-path5;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-gallery .path6 {
    &:before {
        content: $sc-icon-gallery-path6;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-gallery .path7 {
    &:before {
        content: $sc-icon-gallery-path7;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-gallery .path8 {
    &:before {
        content: $sc-icon-gallery-path8;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-gallery_light .path1 {
    &:before {
        content: $sc-icon-gallery_light-path1;
        color: rgb(247, 194, 96);
        opacity: 0.5;
    }
}
.sc-icon-gallery_light .path2 {
    &:before {
        content: $sc-icon-gallery_light-path2;
        margin-left: -1em;
        color: rgb(247, 194, 96);
        opacity: 0.5;
    }
}
.sc-icon-gallery_light .path3 {
    &:before {
        content: $sc-icon-gallery_light-path3;
        margin-left: -1em;
        color: rgb(247, 194, 96);
        opacity: 0.5;
    }
}
.sc-icon-gallery_light .path4 {
    &:before {
        content: $sc-icon-gallery_light-path4;
        margin-left: -1em;
        color: rgb(247, 194, 96);
        opacity: 0.5;
    }
}
.sc-icon-gallery_light .path5 {
    &:before {
        content: $sc-icon-gallery_light-path5;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-gallery_light .path6 {
    &:before {
        content: $sc-icon-gallery_light-path6;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-gallery_light .path7 {
    &:before {
        content: $sc-icon-gallery_light-path7;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-gallery_light .path8 {
    &:before {
        content: $sc-icon-gallery_light-path8;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-glossary .path1 {
    &:before {
        content: $sc-icon-glossary-path1;
        color: rgb(247, 194, 96);
    }
}
.sc-icon-glossary .path2 {
    &:before {
        content: $sc-icon-glossary-path2;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-glossary .path3 {
    &:before {
        content: $sc-icon-glossary-path3;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-glossary .path4 {
    &:before {
        content: $sc-icon-glossary-path4;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-glossary_light .path1 {
    &:before {
        content: $sc-icon-glossary_light-path1;
        color: rgb(247, 194, 96);
        opacity: 0.5;
    }
}
.sc-icon-glossary_light .path2 {
    &:before {
        content: $sc-icon-glossary_light-path2;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-glossary_light .path3 {
    &:before {
        content: $sc-icon-glossary_light-path3;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-glossary_light .path4 {
    &:before {
        content: $sc-icon-glossary_light-path4;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-glossary_transparent {
    &:before {
        content: $sc-icon-glossary_transparent;
    }
}
.sc-icon-share .path1 {
    &:before {
        content: $sc-icon-share-path1;
        color: rgb(247, 194, 96);
    }
}
.sc-icon-share .path2 {
    &:before {
        content: $sc-icon-share-path2;
        margin-left: -1em;
        color: rgb(247, 194, 96);
    }
}
.sc-icon-share .path3 {
    &:before {
        content: $sc-icon-share-path3;
        margin-left: -1em;
        color: rgb(247, 194, 96);
    }
}
.sc-icon-share .path4 {
    &:before {
        content: $sc-icon-share-path4;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}
.sc-icon-share_light .path1 {
    &:before {
        content: $sc-icon-share_light-path1;
        color: rgb(247, 194, 96);
        opacity: 0.5;
    }
}
.sc-icon-share_light .path2 {
    &:before {
        content: $sc-icon-share_light-path2;
        margin-left: -1em;
        color: rgb(247, 194, 96);
        opacity: 0.5;
    }
}
.sc-icon-share_light .path3 {
    &:before {
        content: $sc-icon-share_light-path3;
        margin-left: -1em;
        color: rgb(247, 194, 96);
        opacity: 0.5;
    }
}
.sc-icon-share_light .path4 {
    &:before {
        content: $sc-icon-share_light-path4;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}
.sc-icon-barrel {
    &:before {
        content: $sc-icon-barrel;
    }
}
.sc-icon-beverage {
    &:before {
        content: $sc-icon-beverage;
    }
}
.sc-icon-bowl {
    &:before {
        content: $sc-icon-bowl;
    }
}
.sc-icon-cheese {
    &:before {
        content: $sc-icon-cheese;
    }
}
.sc-icon-cook {
    &:before {
        content: $sc-icon-cook;
    }
}
.sc-icon-dish {
    &:before {
        content: $sc-icon-dish;
    }
}
.sc-icon-herbs {
    &:before {
        content: $sc-icon-herbs;
    }
}
.sc-icon-leaf {
    &:before {
        content: $sc-icon-leaf;
    }
}
.sc-icon-location {
    &:before {
        content: $sc-icon-location;
    }
}
.sc-icon-menu {
    &:before {
        content: $sc-icon-menu;
    }
}
.sc-icon-preserves {
    &:before {
        content: $sc-icon-preserves;
    }
}
.sc-icon-recipe {
    &:before {
        content: $sc-icon-recipe;
    }
}
.sc-icon-recycle {
    &:before {
        content: $sc-icon-recycle;
    }
}
.sc-icon-soup {
    &:before {
        content: $sc-icon-soup;
    }
}
.sc-icon-table_cut {
    &:before {
        content: $sc-icon-table_cut;
    }
}
.sc-icon-whip {
    &:before {
        content: $sc-icon-whip;
    }
}
.sc-icon-wine {
    &:before {
        content: $sc-icon-wine;
    }
}
.sc-icon-binoculars {
    &:before {
        content: $sc-icon-binoculars;
    }
}
.sc-icon-cheese1 {
    &:before {
        content: $sc-icon-cheese1;
    }
}
.sc-icon-chef1 .path1 {
    &:before {
        content: $sc-icon-chef1-path1;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-chef1 .path2 {
    &:before {
        content: $sc-icon-chef1-path2;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-chef1 .path3 {
    &:before {
        content: $sc-icon-chef1-path3;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-chef1 .path4 {
    &:before {
        content: $sc-icon-chef1-path4;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-chef1 .path5 {
    &:before {
        content: $sc-icon-chef1-path5;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-chef1 .path6 {
    &:before {
        content: $sc-icon-chef1-path6;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-chef1 .path7 {
    &:before {
        content: $sc-icon-chef1-path7;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-chef1 .path8 {
    &:before {
        content: $sc-icon-chef1-path8;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-chef1 .path9 {
    &:before {
        content: $sc-icon-chef1-path9;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-chef1 .path10 {
    &:before {
        content: $sc-icon-chef1-path10;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-chef1 .path11 {
    &:before {
        content: $sc-icon-chef1-path11;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-chef1 .path12 {
    &:before {
        content: $sc-icon-chef1-path12;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-chef1 .path13 {
    &:before {
        content: $sc-icon-chef1-path13;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-chef1 .path14 {
    &:before {
        content: $sc-icon-chef1-path14;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-chef1 .path15 {
    &:before {
        content: $sc-icon-chef1-path15;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-chef1 .path16 {
    &:before {
        content: $sc-icon-chef1-path16;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-hat .path1 {
    &:before {
        content: $sc-icon-hat-path1;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-hat .path2 {
    &:before {
        content: $sc-icon-hat-path2;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-hat .path3 {
    &:before {
        content: $sc-icon-hat-path3;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-hat_and_barilla {
    &:before {
        content: $sc-icon-hat_and_barilla;
    }
}
.sc-icon-italy1 {
    &:before {
        content: $sc-icon-italy1;
    }
}
.sc-icon-location1 .path1 {
    &:before {
        content: $sc-icon-location1-path1;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-location1 .path2 {
    &:before {
        content: $sc-icon-location1-path2;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-location1 .path3 {
    &:before {
        content: $sc-icon-location1-path3;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-mail {
    &:before {
        content: $sc-icon-mail;
    }
}
.sc-icon-map .path1 {
    &:before {
        content: $sc-icon-map-path1;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-map .path2 {
    &:before {
        content: $sc-icon-map-path2;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-map .path3 {
    &:before {
        content: $sc-icon-map-path3;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-map .path4 {
    &:before {
        content: $sc-icon-map-path4;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-night .path1 {
    &:before {
        content: $sc-icon-night-path1;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-night .path2 {
    &:before {
        content: $sc-icon-night-path2;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-night .path3 {
    &:before {
        content: $sc-icon-night-path3;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-night .path4 {
    &:before {
        content: $sc-icon-night-path4;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-night .path5 {
    &:before {
        content: $sc-icon-night-path5;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-night .path6 {
    &:before {
        content: $sc-icon-night-path6;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-night .path7 {
    &:before {
        content: $sc-icon-night-path7;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-night .path8 {
    &:before {
        content: $sc-icon-night-path8;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-search1 {
    &:before {
        content: $sc-icon-search1;
    }
}
.sc-icon-sight .path1 {
    &:before {
        content: $sc-icon-sight-path1;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-sight .path2 {
    &:before {
        content: $sc-icon-sight-path2;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-sight .path3 {
    &:before {
        content: $sc-icon-sight-path3;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-sight .path4 {
    &:before {
        content: $sc-icon-sight-path4;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-table .path1 {
    &:before {
        content: $sc-icon-table-path1;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-table .path2 {
    &:before {
        content: $sc-icon-table-path2;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-table .path3 {
    &:before {
        content: $sc-icon-table-path3;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-table .path4 {
    &:before {
        content: $sc-icon-table-path4;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-table .path5 {
    &:before {
        content: $sc-icon-table-path5;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-table .path6 {
    &:before {
        content: $sc-icon-table-path6;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-table .path7 {
    &:before {
        content: $sc-icon-table-path7;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-table .path8 {
    &:before {
        content: $sc-icon-table-path8;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-table .path9 {
    &:before {
        content: $sc-icon-table-path9;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}
.sc-icon-table .path10 {
    &:before {
        content: $sc-icon-table-path10;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-table .path11 {
    &:before {
        content: $sc-icon-table-path11;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-table .path12 {
    &:before {
        content: $sc-icon-table-path12;
        margin-left: -1em;
        color: rgb(75, 58, 51);
    }
}
.sc-icon-user1 {
    &:before {
        content: $sc-icon-user1;
    }
}
.sc-icon-wine1 {
    &:before {
        content: $sc-icon-wine1;
    }
}
.sc-icon-broth_spoon {
    &:before {
        content: $sc-icon-broth_spoon;
    }
}
.sc-icon-butter_knife {
    &:before {
        content: $sc-icon-butter_knife;
    }
}
.sc-icon-dessert_fork {
    &:before {
        content: $sc-icon-dessert_fork;
    }
}
.sc-icon-dessert_knife {
    &:before {
        content: $sc-icon-dessert_knife;
    }
}
.sc-icon-dessert_spoon {
    &:before {
        content: $sc-icon-dessert_spoon;
    }
}
.sc-icon-fish_fork {
    &:before {
        content: $sc-icon-fish_fork;
    }
}
.sc-icon-fish_knife {
    &:before {
        content: $sc-icon-fish_knife;
    }
}
.sc-icon-meat_knife {
    &:before {
        content: $sc-icon-meat_knife;
    }
}
.sc-icon-pasta_fork {
    &:before {
        content: $sc-icon-pasta_fork;
    }
}
.sc-icon-soup_spoon {
    &:before {
        content: $sc-icon-soup_spoon;
    }
}
.sc-icon-steak_knife {
    &:before {
        content: $sc-icon-steak_knife;
    }
}
.sc-icon-ballon {
    &:before {
        content: $sc-icon-ballon;
    }
}
.sc-icon-borgagna {
    &:before {
        content: $sc-icon-borgagna;
    }
}
.sc-icon-coppa {
    &:before {
        content: $sc-icon-coppa;
    }
}
.sc-icon-flute {
    &:before {
        content: $sc-icon-flute;
    }
}
.sc-icon-gran_ballon {
    &:before {
        content: $sc-icon-gran_ballon;
    }
}
.sc-icon-renato {
    &:before {
        content: $sc-icon-renato;
    }
}
.sc-icon-sautern {
    &:before {
        content: $sc-icon-sautern;
    }
}
.sc-icon-tulipano {
    &:before {
        content: $sc-icon-tulipano;
    }
}
.sc-icon-tulipano_piccolo {
    &:before {
        content: $sc-icon-tulipano_piccolo;
    }
}
.sc-icon-eggs {
    &:before {
        content: $sc-icon-eggs;
    }
}
.sc-icon-fruits {
    &:before {
        content: $sc-icon-fruits;
    }
}
.sc-icon-gluten {
    &:before {
        content: $sc-icon-gluten;
    }
}
.sc-icon-lacteal {
    &:before {
        content: $sc-icon-lacteal;
    }
}
.sc-icon-shelfish {
    &:before {
        content: $sc-icon-shelfish;
    }
}
