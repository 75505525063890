@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;

.akt-recipes-breadcrumb {
    @extend %container;
    height: 17px;
    margin-top: 75px + 13px;
    margin-bottom: 21px;

    @include respond-above(l) {
        margin-top: 108px + 30px;
        margin-bottom: 40px;
    }

    &__navList {
        display: flex;
        justify-content: flex-start;
        list-style: none;
        padding-inline-start: 0;
        margin-bottom: 0;
        overflow-x: scroll;

        @include respond-above(l) {
            overflow-x: unset;
        }
    }

    &__navItem {
        display: inline-block;
        white-space: nowrap;
        
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.17px;

        &:not(:last-child)::after {
            content: ">";
            padding: 0 5px 0 3px;
        }

        &--link {
            text-decoration: none;
            color: #000000;

            &:hover {
                color: #F7C260;
            }
        }

        &:last-child {
            color: #919191;

            a {
                color: #919191;
            }
        }
    }
}