@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.st2-paginator {
    margin-top: rem(41px);
    margin-bottom: rem(42px);
    display: flex;
    justify-content: center;

    @include respond-above(l) {
        margin-top: rem(95px);
    }

    &__pageItems {
        display: flex;
        justify-content: center;
    }

    &__pageItem,
    &__pageBack,
    &__pageNext {
        display: block;
        cursor: pointer;
        @extend %buttonReset;
    }

    &__pageBack {
        margin-right: rem(33px);
        color: alpha(--color-dark-yellow, 50%);

        &::after {
            content: '<';
            font-size: rem(16px);
        }

        & > span {
            display: none;
            @extend %font-ced-label;

            &:hover {
                color: var(--color-dark-yellow);
            }
        }

        @include respond-above(l) {
            &::after {
                display: none;
            }

            & > span {
                display: block;
            }
        }
    }

    &__pageNext {
        margin-left: rem(33px);
        color: alpha(--color-dark-yellow, 50%);

        &::after {
            content: '>';
            font-size: rem(16px);
        }

        & > span {
            display: none;
            @extend %font-ced-label;

            &:hover {
                color: var(--color-dark-yellow);
            }
        }

        @include respond-above(l) {
            &::after {
                display: none;
            }

            & > span {
                display: block;
            }
        }
    }

    &__pageItem {
        min-width: rem(30px);
        height: rem(30px);
        line-height: rem(30px);
        margin-right: rem(10px);
        text-align: center;
        @extend %font-paragraph;
        color: var(--color-black);

        &:last-child {
            margin-right: 0;
        }

        &--active {
            font-weight: 700;

            background-color: var(--color-dark-yellow);
            box-shadow: -3px 3px 0px 0px alpha(--color-dark-yellow, 0.5);
            border: none;

            &:hover {
                color: var(--color-black);
            }
        }
    }
}
