@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.st13-collection-card {
    margin-top: rem(41px);
    margin-bottom: rem(42px);

    &__container {
        position: relative;
    }

    &__pic,
    &__media {
        width: rem(295px);
        height: rem(233px);

        @include respond-above(l) {
            width: rem(535px);
            height: rem(369px);
        }
    }

    &__texts {
        position: absolute;
        right: rem(60px);
        bottom: 0.8125rem;
        left: rem(32px);
        line-height: 1.3;
        min-height: rem(33px);
        color: var(--color-black);

        @include respond-above(l) {
            font-size: rem(24px);
            right: rem(270px);
            bottom: rem(63px);
        }
    }

    &__recTitle {
        @extend %font-h4;
        line-height: 1.8rem;
        & > span {
            & > a {
                color: var(--color-black);
            }

            background: alpha(--color-white, 0.8);
            box-shadow: 10px 0 0 alpha(--color-white, 0.8), -10px 0 0 alpha(--color-white, 0.8);
        }
    }

    &__description {
        @extend %font-paragraph;

        & > span {
            background: alpha(--color-white, 0.8);
            box-shadow: 10px 0 0 alpha(--color-white, 0.8), -10px 0 0 alpha(--color-white, 0.8);
        }
    }

    &__ctas {
        display: flex;
        flex-direction: row;
        position: absolute;
        top: rem(28px);
        right: rem(20px);
        left: auto;
        bottom: rem(430px);
    }

}
