@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.st6-slider {
    position: relative;

    &__carousel {
        width: 100%;
        overflow: hidden;
        margin: 0 auto;
    }

    &__track {
        width: 100%;
        height: 100%;
        display: flex;
        transition-property: transform;
        box-sizing: content-box;
    }

    &__slideContainer {
        position: relative;
        flex-shrink: 0;
        transition-property: transform;
        display: flex;

        &--full {
            width: 100%;
        }
    }

    &__pagination {
        margin-top: rem(24px);
        display: flex;
        justify-content: center;

        @include respond-above(l) {
            display: flex;
        }

        &--hidden {
            display: none;
        }
    }

    &__bullet {
        flex: 0 0 auto;
        height: rem(9px);
        width: rem(9px);
        border-radius: rem(9px);
        background-color: alpha(--color-ground-brown, 0.5);

        &:not(:last-child) {
            margin-right: rem(9px);
        }

        &--active {
            background-color: var(--color-ground-brown);
        }
    }

    &__btn {
        @extend %buttonReset;
        position: absolute;
        cursor: pointer;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);

        &--prev {
            display: none;
            transform: translateY(-50%) rotateZ(180deg);

            @include respond-above(l) {
                display: block;
                left: rem(-23px);
            }
        }

        &--next {
            display: none;
            @include respond-above(l) {
                display: block;
                right: rem(-23px);
            }
        }

        &--hidden {
            display: none;
        }

        &--disabled {
            opacity: 0.5;
        }

        &::after {
            content: '';
            background-image: url(../../../../../resources/saporie/images/black_left_arrow.svg);
            display: block;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center center;
            width: 0.9375rem;
            height: rem(18px);
        }
    }

    &__mapActiveCard {
        .swiper-slide-active { 
            .st22-poi-map-card {
                border: solid 1.5px var(--color-brown);
            }
        }
    }
}
