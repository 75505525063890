@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.st10-tag {
    @extend %font-label;
    font-weight: 500;
    @extend %buttonReset;
    color: var(--color-black);
    height: rem(32px);
    line-height: rem(28px);
    padding-left: rem(16px);
    padding-right: rem(36px);
    border: 2px solid var(--color-ground-brown);
    margin-bottom: rem(10px);
    border-radius: rem(16px);
    margin-right: rem(10px);
    position: relative;
    cursor: pointer;

    &:last-child {
        margin-bottom: rem(27px);
    }

    &::after {
        @extend %sc-icon-base;
        content: $sc-icon-cancel;
        position: absolute;
        vertical-align: middle;
        right: rem(11px);
        font-size: rem(12px);
        line-height: inherit;
    }
}
