@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.st11-actions {
    font-size: rem(24px);

    &__btn {
        @extend %buttonReset;
        cursor: pointer;

        & > span {
            pointer-events: none;
        }

        &:hover {
        }

        &--circles {
            height: rem(40px);
            width: rem(40px);
            background-color: var(--color-dark-yellow);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__btn--save {
        & > .path1:before {
            color: #fdf3df;
        }

        &:hover {
            & > .path1:before {
                color: #F3B136;
            }
        }

        &[data-id-save] {
            & > .path1:before {
                color: #F3B136;
            }
        }
    }

    &__btn--share {
        & > .path1,
        & > .path2,
        & > .path3 {
            opacity: 0.5;
        }

        &:hover {
            & > .path1,
            & > .path2,
            & > .path3 {
                opacity: 1;
            }
        }
    }

    &__btnLabel {
        @extend %font-label-v2;
        margin-left: rem(5px);
    }

    &__btnCnt {
        display: inline-block;
        position: relative;
        margin-right: 0.83em;
        &:last-child {
            margin-right: 0;
        }

        &--label {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    

    &__shares {
        font-size: rem(14px);

        pointer-events: none;
        opacity: 0;
        border-radius: 5px;
        position: absolute;
        padding: 0 rem(10px);
        width: rem(197px);
        height: auto;
        background-color: var(--color-sandy-beach);
        z-index: setZ(dropdown);

        box-shadow: 0px 1px 2px 0px var(--color-black);
        top: calc(100% + 5px);
        transition: opacity 0.3s ease-in-out;

        &--show {
            pointer-events: all;
            opacity: 1;
        }

        &--toRight {
            left: 0;
        }

        &--toLeft {
            right: 0;
        }

        &--onTop {
            top: unset;
            bottom: calc(100% + 10px);
        }
    }
}
