@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;


.sc19-map {

    &__texts {
        @extend %container;
    }

    &__title {
        @extend %font-h3;
        font-weight: 600;
    }

    &__description {
        @extend %font-h6;
        font-weight: normal;
    }

    &__map {
        width: 100%;
        height: 100%;
    }

    &__mapContainer {
        height: rem(462px);
        display: block;
        width: 100%;

        &--preShow {
            display: block;
            opacity: 0;
            pointer-events: none;
        }

        &--show {
            opacity: 1;
            pointer-events: all;
            transition: opacity 0.5s ease-in-out;
        }

    }

    &__slider {
        margin-left: rem(21px);
        margin-right: rem(21px);
        margin-top: rem(29px);
        visibility: visible;
        pointer-events: all;

        @include respond-above(l) {
            max-height: 0;
            visibility: hidden;
            pointer-events: none;
        }
    }

    &__sliderDsk {
        margin-top: rem(29px);
        @extend %container;
        visibility: visible;
        pointer-events: all;

        @include respond-below(l) {
            max-height: 0;
            visibility: hidden;
            pointer-events: none;
        }
    }

}
