@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;

.sc6-banner {
    &--container {
        @extend %container;
    }

    &__pic {
        width: 100%;
    }

    &__banner--small {
        padding: 0;
        margin: 0;
        width: 100%;
        height: rem(280px);
    }

    &__banner {
        width: 100%;
        height: rem(100px);

        @include respond-above(l) {
            height: rem(200px);
        }
    
        &--withoutHeightLimit {
            height: unset;
        }
    }
}
