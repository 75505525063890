@use '../modules/variables' as *;
@use '../modules/commons' as *;

/* ICONS LIBRARY */
/*Allergy*/
.allergy-icons {
    width: 30px;
    height: 30px;
    background-size: 100% 100%;
    margin: auto;
}

.allergy-icons.eggs-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/allergy/eggs.svg');
}

.allergy-icons.fruits-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/allergy/fruits.svg');
}

.allergy-icons.gluten-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/allergy/gluten.svg');
}

.allergy-icons.lacteal-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/allergy/lacteal.svg');
}

.allergy-icons.shelfish-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/allergy/shelfish.svg');
}

/*Summary*/
.summary-icons {
    width: 24px;
    height: 24px;
    background-size: 100% 100%;
    margin: auto;
}

.summary-icons.alert-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/alert.svg');
}

.summary-icons.autumn-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/autumn.svg');
}

.summary-icons.casserole-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/casserole.svg');
}

.summary-icons.chef-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/chef.svg');
}

.summary-icons.fruit-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/fruit.svg');
}

.summary-icons.calorie-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/calorie.svg');
}
.summary-icons.italy-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/italy.svg');
}

.summary-icons.oven-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/oven.svg');
}

.summary-icons.party-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/party.svg');
}

.summary-icons.pasta-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/pasta.svg');
}

.summary-icons.people-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/people.svg');
}

.summary-icons.plate-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/plate.svg');
}

.summary-icons.spring-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/spring.svg');
}

.summary-icons.summer-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/summer.svg');
}

.summary-icons.time-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/time.svg');
}

.summary-icons.winter-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/winter.svg');
}

.summary-icons.search-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/search.svg');
}

.summary-icons.user-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/user.svg');
}

.summary-icons.user_light-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/user_light.svg');
}

.summary-icons.cancel-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/cancel.svg');
}

.summary-icons.cancel_light-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/cancel_light.svg');
}

.summary-icons.tick-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/tick.svg');
}

.summary-icons.see-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/see.svg');
}

.summary-icons.see_cross-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/see_cross.svg');
}

.summary-icons.groceries-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/groceries.svg');
}

.summary-icons.video-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/summary/video.svg');
}

/*Options*/
.options-icons {
    width: 24px;
    height: 24px;
    background-size: 100% 100%;
    margin: auto;
}

.options-icons.bookmark-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/options/bookmark.svg');
}

.options-icons.bookmark_light-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/options/bookmark_light.svg');
}

.options-icons.bookmark_light-icon:hover {
    background-image: url('#{$resourcesFolder}/saporie/icons/options/bookmark.svg');
}

.options-icons.gallery-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/options/gallery.svg');
}

.options-icons.gallery_light-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/options/gallery_light.svg');
}

.options-icons.gallery_light-icon:hover {
    background-image: url('#{$resourcesFolder}/saporie/icons/options/gallery.svg');
}

.options-icons.glossary_transparent-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/options/glossary_transparent.svg');
}

.options-icons.glossary-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/options/glossary.svg');
}

.options-icons.glossary_light-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/options/glossary_light.svg');
}

.options-icons.glossary_light-icon:hover {
    background-image: url('#{$resourcesFolder}/saporie/icons/options/glossary.svg');
}

.options-icons.share-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/options/share.svg');
}

.options-icons.share_light-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/options/share_light.svg');
}

.options-icons.share_light-icon:hover {
    background-image: url('#{$resourcesFolder}/saporie/icons/options/share.svg');
}

/*Social*/
.social-icons {
    width: 50px;
    height: 50px;
    background-size: 100% 100%;
    margin: auto;
}

.social-icons.fb-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/social/fb.svg');
}

.social-icons.google-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/social/google.svg');
}

.social-icons.instagram-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/social/instagram.svg');
}

.social-icons.pinterest-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/social/pinterest.svg');
}

.social-icons.twitter-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/social/twitter.svg');
}

.social-icons.youtube-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/social/youtube.svg');
}

/*Head*/
.head-icons {
    width: 90px;
    height: 90px;
    background-size: 100% 100%;
    margin: auto;
}

.head-icons.binoculars-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/head/binoculars.svg');
}

.head-icons.cheese-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/head/cheese.svg');
}

.head-icons.chef-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/head/chef.svg');
}

.head-icons.hat-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/head/hat.svg');
}

.head-icons.hat_and_barilla-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/head/hat_and_barilla.svg');
}

.head-icons.italy-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/head/italy.svg');
}

.head-icons.italy_invert-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/head/italy_invert.svg');
}

.head-icons.location-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/head/location.svg');
}

.head-icons.mail-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/head/mail.svg');
}

.head-icons.map-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/head/map.svg');
}

.head-icons.map_invert-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/head/map_invert.svg');
}

.head-icons.night-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/head/night.svg');
}

.head-icons.restaurant_invert-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/head/restaurant_invert.svg');
}

.head-icons.search-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/head/search.svg');
}

.head-icons.sight-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/head/sight.svg');
}

.head-icons.table-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/head/table.svg');
}

.head-icons.user-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/head/user.svg');
}

.head-icons.user_invert-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/head/user_invert.svg');
}

.head-icons.wine-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/head/wine.svg');
}

.head-icons.pointer-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/head/pointer.svg');
}

/*Spoted icons*/
.spoted-icons {
    width: 90px;
    height: 90px;
    background-size: 100% 100%;
    margin: auto;
}

.spoted-icons.barrel-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/spoted/barrel.svg');
}

.spoted-icons.beverage-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/spoted/beverage.svg');
}

.spoted-icons.bowl-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/spoted/bowl.svg');
}

.spoted-icons.cheese-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/spoted/cheese.svg');
}

.spoted-icons.cook-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/spoted/cook.svg');
}

.spoted-icons.dish-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/spoted/dish.svg');
}

.spoted-icons.herbs-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/spoted/herbs.svg');
}

.spoted-icons.leaf-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/spoted/leaf.svg');
}

.spoted-icons.location-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/spoted/location.svg');
}

.spoted-icons.menu-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/spoted/menu.svg');
}

.spoted-icons.preserves-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/spoted/preserves.svg');
}

.spoted-icons.recipe-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/spoted/recipe.svg');
}

.spoted-icons.recycle-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/spoted/recycle.svg');
}

.spoted-icons.soup-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/spoted/soup.svg');
}

.spoted-icons.table_cut-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/spoted/table_cut.svg');
}

.spoted-icons.whip-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/spoted/whip.svg');
}

.spoted-icons.wine-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/spoted/wine.svg');
}

/*Cups*/
.cups-icons {
    width: 100px;
    height: 100px;
    background-size: 100% 100%;
    margin: auto;
}

.cups-icons.ballon-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/cups/ballon.svg');
}

.cups-icons.borgagna-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/cups/borgagna.svg');
}

.cups-icons.coppa-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/cups/coppa.svg');
}

.cups-icons.flute-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/cups/flute.svg');
}

.cups-icons.gran_ballon-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/cups/gran_ballon.svg');
}

.cups-icons.renato-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/cups/renato.svg');
}

.cups-icons.sautern-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/cups/sautern.svg');
}

.cups-icons.tulipano-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/cups/tulipano.svg');
}

.cups-icons.tulipano_piccolo-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/cups/tulipano_piccolo.svg');
}

/*Cutlery*/
.cutlery-icons {
    width: 160px;
    height: 160px;
    background-size: 100% 100%;
    margin: auto;
}

.cutlery-icons.broth_spoon-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/cutlery/broth_spoon.svg');
}

.cutlery-icons.butter_knife-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/cutlery/butter_knife.svg');
}

.cutlery-icons.dessert_fork-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/cutlery/dessert_fork.svg');
}

.cutlery-icons.dessert_knife-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/cutlery/dessert_knife.svg');
}

.cutlery-icons.dessert_spoon-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/cutlery/dessert_spoon.svg');
}

.cutlery-icons.fish_fork-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/cutlery/fish_fork.svg');
}

.cutlery-icons.fish_knife-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/cutlery/fish_knife.svg');
}

.cutlery-icons.meat_knife-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/cutlery/meat_knife.svg');
}

.cutlery-icons.pasta_fork-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/cutlery/pasta_fork.svg');
}

.cutlery-icons.soup_spoon-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/cutlery/pasta_fork.svg');
}

.cutlery-icons.steak_knife-icon {
    background-image: url('#{$resourcesFolder}/saporie/icons/cutlery/pasta_fork.svg');
}

/* COLOR MASKING */
/*Allergy*/
.allergy-icons.svg-mask.eggs-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/allergy/eggs.svg');
}

.allergy-icons.svg-mask.fruits-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/allergy/fruits.svg');
}

.allergy-icons.svg-mask.gluten-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/allergy/gluten.svg');
}

.allergy-icons.svg-mask.lacteal-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/allergy/lacteal.svg');
}

.allergy-icons.svg-mask.shelfish-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/allergy/shelfish.svg');
}

/*Summary*/
.summary-icons.svg-mask.alert-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/alert.svg');
}

.summary-icons.svg-mask.autumn-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/autumn.svg');
}

.summary-icons.svg-mask.casserole-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/casserole.svg');
}

.summary-icons.svg-mask.chef-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/chef.svg');
}

.summary-icons.svg-mask.fruit-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/fruit.svg');
}

.summary-icons.svg-mask.calorie-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/calorie.svg');
}

.summary-icons.svg-mask.italy-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/italy.svg');
}

.summary-icons.svg-mask.oven-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/oven.svg');
}

.summary-icons.svg-mask.party-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/party.svg');
}

.summary-icons.svg-mask.pasta-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/pasta.svg');
}

.summary-icons.svg-mask.people-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/people.svg');
}

.summary-icons.svg-mask.plate-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/plate.svg');
}

.summary-icons.svg-mask.spring-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/spring.svg');
}

.summary-icons.svg-mask.summer-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/summer.svg');
}

.summary-icons.svg-mask.time-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/time.svg');
}

.summary-icons.svg-mask.winter-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/winter.svg');
}

.summary-icons.svg-mask.search-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/search.svg');
}

.summary-icons.svg-mask.user-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/user.svg');
}

.summary-icons.svg-mask.cancel-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/cancel.svg');
}

.summary-icons.svg-mask.tick-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/tick.svg');
}

.summary-icons.svg-mask.cancel_light-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/cancel_light.svg');
}

.summary-icons.svg-mask.user_light-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/user_light.svg');
}

.summary-icons.svg-mask.groceries-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/groceries.svg');
}

.summary-icons.svg-mask.video-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/summary/video.svg');
}

/*Options*/
.options-icons.svg-mask.bookmark-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/options/bookmark.svg');
}

.options-icons.svg-mask.bookmark_light-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/options/bookmark_light.svg');
}

.options-icons.svg-mask.gallery-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/options/gallery.svg');
}

.options-icons.svg-mask.gallery_light-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/options/gallery_light.svg');
}

.options-icons.svg-mask.glossary-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/options/glossary.svg');
}

.options-icons.svg-mask.glossary_light-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/options/glossary_light.svg');
}

.options-icons.svg-mask.share-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/options/share.svg');
}

.options-icons.svg-mask.share_light-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/options/share_light.svg');
}

/*Head*/
.head-icons.svg-mask.binoculars-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/head/binoculars.svg');
}

.head-icons.svg-mask.cheese-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/head/cheese.svg');
}

.head-icons.svg-mask.chef-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/head/chef.svg');
}

.head-icons.svg-mask.hat-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/head/hat.svg');
}

.head-icons.svg-mask.hat_and_barilla-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/head/hat_and_barilla.svg');
}

.head-icons.svg-mask.italy-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/head/italy.svg');
}

.head-icons.svg-mask.italy_invert-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/head/italy_invert.svg');
}

.head-icons.svg-mask.location-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/head/location.svg');
}

.head-icons.svg-mask.mail-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/head/mail.svg');
}

.head-icons.svg-mask.map-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/head/map.svg');
}

.head-icons.svg-mask.map_invert-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/head/map_invert.svg');
}

.head-icons.svg-mask.night-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/head/night.svg');
}

.head-icons.svg-mask.restaurant_invert-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/head/restaurant_invert.svg');
}

.head-icons.svg-mask.search-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/head/search.svg');
}

.head-icons.svg-mask.sight-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/head/sight.svg');
}

.head-icons.svg-mask.table-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/head/table.svg');
}

.head-icons.svg-mask.user-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/head/user.svg');
}

.head-icons.svg-mask.user_invert-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/head/user_invert.svg');
}

.head-icons.svg-mask.wine-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/head/wine.svg');
}

.head-icons.svg-mask.pointer-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/head/pointer.svg');
}

/*Spoted icons*/
.spoted-icons.svg-mask.barrel-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/spoted/barrel.svg');
}

.spoted-icons.svg-mask.beverage-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/spoted/beverage.svg');
}

.spoted-icons.svg-mask.bowl-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/spoted/bowl.svg');
}

.spoted-icons.svg-mask.cheese-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/spoted/cheese.svg');
}

.spoted-icons.svg-mask.cook-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/spoted/cook.svg');
}

.spoted-icons.svg-mask.dish-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/spoted/dish.svg');
}

.spoted-icons.svg-mask.herbs-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/spoted/herbs.svg');
}

.spoted-icons.svg-mask.leaf-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/spoted/leaf.svg');
}

.spoted-icons.svg-mask.location-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/spoted/location.svg');
}

.spoted-icons.svg-mask.menu-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/spoted/menu.svg');
}

.spoted-icons.svg-mask.preserves-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/spoted/preserves.svg');
}

.spoted-icons.svg-mask.recipe-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/spoted/recipe.svg');
}

.spoted-icons.svg-mask.recycle-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/spoted/recycle.svg');
}

.spoted-icons.svg-mask.soup-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/spoted/soup.svg');
}

.spoted-icons.svg-mask.table_cut-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/spoted/table_cut.svg');
}

.spoted-icons.svg-mask.whip-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/spoted/whip.svg');
}

.spoted-icons.svg-mask.wine-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/spoted/wine.svg');
}

/*Cups*/
.cups-icons.svg-mask.ballon-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/cups/ballon.svg');
}

.cups-icons.svg-mask.borgagna-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/cups/borgagna.svg');
}

.cups-icons.svg-mask.coppa-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/cups/coppa.svg');
}

.cups-icons.svg-mask.flute-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/cups/flute.svg');
}

.cups-icons.svg-mask.gran_ballon-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/cups/gran_ballon.svg');
}

.cups-icons.svg-mask.renato-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/cups/renato.svg');
}

.cups-icons.svg-mask.sautern-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/cups/sautern.svg');
}

.cups-icons.svg-mask.tulipano-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/cups/tulipano.svg');
}

.cups-icons.svg-mask.tulipano_piccolo-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/cups/tulipano_piccolo.svg');
}

/*Cutlery*/
.cutlery-icons.svg-mask.broth_spoon-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/cutlery/broth_spoon.svg');
}

.cutlery-icons.svg-mask.butter_knife-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/cutlery/butter_knife.svg');
}

.cutlery-icons.svg-mask.dessert_fork-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/cutlery/dessert_fork.svg');
}

.cutlery-icons.svg-mask.dessert_knife-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/cutlery/dessert_knife.svg');
}

.cutlery-icons.svg-mask.dessert_spoon-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/cutlery/dessert_spoon.svg');
}

.cutlery-icons.svg-mask.fish_fork-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/cutlery/fish_fork.svg');
}

.cutlery-icons.svg-mask.fish_knife-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/cutlery/fish_knife.svg');
}

.cutlery-icons.svg-mask.meat_knife-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/cutlery/meat_knife.svg');
}

.cutlery-icons.svg-mask.pasta_fork-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/cutlery/pasta_fork.svg');
}

.cutlery-icons.svg-mask.soup_spoon-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/cutlery/pasta_fork.svg');
}

.cutlery-icons.svg-mask.steak_knife-icon {
    background-image: none;
    -webkit-mask-image: url('#{$resourcesFolder}/saporie/icons/cutlery/pasta_fork.svg');
}

@media screen and (max-width: 1024px) {
    .social-icons {
        width: 33px;
        height: 33px;
        background-size: 100% 100%;
        margin: auto;
    }
}
