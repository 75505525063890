//general
$text-base-size: 16px;

//breakpoints
$breakpoints: (
    m: 768px,
    l: 1024px,
    xl: 1280px,
);

//container ( sizing include padding )
$container-wpad: 20px;
$containers: (
    m: 768px,
    l: 1024px,
    xl: 1128px,
);

//header
$header-height: 90px;
$header-height-dsk: 105px;

//filters
$filters-height: 64px;

// z-index
$z-layers: (
    bottomless-pit: -9999,
    header: 10,
    backToTop: 3999,
    dropdown: 3000,
    overlay: 4000,
    modal: 5002,
    popup: 6000,
    negative: -1,
) !default;

$resourcesFolder: '~/src/main/webpack/resources';
$fontsFolder: '#{$resourcesFolder}/saporie/fonts';

// icons
$icomoon-font-family: 'saporie-v2' !default;

$sc-icon-fb-path1: '\e900';
$sc-icon-fb-path2: '\e901';
$sc-icon-google-path1: '\e902';
$sc-icon-google-path2: '\e903';
$sc-icon-google-path3: '\e904';
$sc-icon-instagram-path1: '\e905';
$sc-icon-instagram-path2: '\e906';
$sc-icon-pinterest-path1: '\e907';
$sc-icon-pinterest-path2: '\e908';
$sc-icon-twitter-path1: '\e909';
$sc-icon-twitter-path2: '\e90a';
$sc-icon-youtube-path1: '\e90b';
$sc-icon-youtube-path2: '\e90c';
$sc-icon-alert: '\e90d';
$sc-icon-autumn: '\e90e';
$sc-icon-calorie: '\e90f';
$sc-icon-cancel: '\e910';
$sc-icon-casserole: '\e911';
$sc-icon-chef: '\e912';
$sc-icon-fruit: '\e913';
$sc-icon-groceries: '\e914';
$sc-icon-italy: '\e915';
$sc-icon-oven: '\e916';
$sc-icon-party: '\e917';
$sc-icon-pasta: '\e918';
$sc-icon-people: '\e919';
$sc-icon-plate: '\e91a';
$sc-icon-search: '\e91b';
$sc-icon-see: '\e91c';
$sc-icon-see_cross: '\e91d';
$sc-icon-spring: '\e91e';
$sc-icon-summer: '\e91f';
$sc-icon-tick: '\e920';
$sc-icon-time: '\e921';
$sc-icon-user: '\e922';
$sc-icon-video: '\e923';
$sc-icon-winter: '\e924';
$sc-icon-bookmark-path1: '\e925';
$sc-icon-bookmark-path2: '\e926';
$sc-icon-bookmark_light-path1: '\e927';
$sc-icon-bookmark_light-path2: '\e928';
$sc-icon-gallery-path1: '\e929';
$sc-icon-gallery-path2: '\e92a';
$sc-icon-gallery-path3: '\e92b';
$sc-icon-gallery-path4: '\e92c';
$sc-icon-gallery-path5: '\e92d';
$sc-icon-gallery-path6: '\e92e';
$sc-icon-gallery-path7: '\e92f';
$sc-icon-gallery-path8: '\e930';
$sc-icon-gallery_light-path1: '\e931';
$sc-icon-gallery_light-path2: '\e932';
$sc-icon-gallery_light-path3: '\e933';
$sc-icon-gallery_light-path4: '\e934';
$sc-icon-gallery_light-path5: '\e935';
$sc-icon-gallery_light-path6: '\e936';
$sc-icon-gallery_light-path7: '\e937';
$sc-icon-gallery_light-path8: '\e938';
$sc-icon-glossary-path1: '\e939';
$sc-icon-glossary-path2: '\e93a';
$sc-icon-glossary-path3: '\e93b';
$sc-icon-glossary-path4: '\e93c';
$sc-icon-glossary_light-path1: '\e93d';
$sc-icon-glossary_light-path2: '\e93e';
$sc-icon-glossary_light-path3: '\e93f';
$sc-icon-glossary_light-path4: '\e940';
$sc-icon-glossary_transparent: '\e941';
$sc-icon-share-path1: '\e942';
$sc-icon-share-path2: '\e943';
$sc-icon-share-path3: '\e944';
$sc-icon-share-path4: '\e945';
$sc-icon-share_light-path1: '\e946';
$sc-icon-share_light-path2: '\e947';
$sc-icon-share_light-path3: '\e948';
$sc-icon-share_light-path4: '\e949';
$sc-icon-barrel: '\e94a';
$sc-icon-beverage: '\e94b';
$sc-icon-bowl: '\e94c';
$sc-icon-cheese: '\e94d';
$sc-icon-cook: '\e94e';
$sc-icon-dish: '\e94f';
$sc-icon-herbs: '\e950';
$sc-icon-leaf: '\e951';
$sc-icon-location: '\e952';
$sc-icon-menu: '\e953';
$sc-icon-preserves: '\e954';
$sc-icon-recipe: '\e955';
$sc-icon-recycle: '\e956';
$sc-icon-soup: '\e957';
$sc-icon-table_cut: '\e958';
$sc-icon-whip: '\e959';
$sc-icon-wine: '\e95a';
$sc-icon-binoculars: '\e95b';
$sc-icon-cheese1: '\e95c';
$sc-icon-chef1-path1: '\e95d';
$sc-icon-chef1-path2: '\e95e';
$sc-icon-chef1-path3: '\e95f';
$sc-icon-chef1-path4: '\e960';
$sc-icon-chef1-path5: '\e961';
$sc-icon-chef1-path6: '\e962';
$sc-icon-chef1-path7: '\e963';
$sc-icon-chef1-path8: '\e964';
$sc-icon-chef1-path9: '\e965';
$sc-icon-chef1-path10: '\e966';
$sc-icon-chef1-path11: '\e967';
$sc-icon-chef1-path12: '\e968';
$sc-icon-chef1-path13: '\e969';
$sc-icon-chef1-path14: '\e96a';
$sc-icon-chef1-path15: '\e96b';
$sc-icon-chef1-path16: '\e96c';
$sc-icon-hat-path1: '\e96d';
$sc-icon-hat-path2: '\e96e';
$sc-icon-hat-path3: '\e96f';
$sc-icon-hat_and_barilla: '\e970';
$sc-icon-italy1: '\e971';
$sc-icon-location1-path1: '\e972';
$sc-icon-location1-path2: '\e973';
$sc-icon-location1-path3: '\e974';
$sc-icon-mail: '\e975';
$sc-icon-map-path1: '\e976';
$sc-icon-map-path2: '\e977';
$sc-icon-map-path3: '\e978';
$sc-icon-map-path4: '\e979';
$sc-icon-night-path1: '\e97a';
$sc-icon-night-path2: '\e97b';
$sc-icon-night-path3: '\e97c';
$sc-icon-night-path4: '\e97d';
$sc-icon-night-path5: '\e97e';
$sc-icon-night-path6: '\e97f';
$sc-icon-night-path7: '\e980';
$sc-icon-night-path8: '\e981';
$sc-icon-search1: '\e982';
$sc-icon-sight-path1: '\e983';
$sc-icon-sight-path2: '\e984';
$sc-icon-sight-path3: '\e985';
$sc-icon-sight-path4: '\e986';
$sc-icon-table-path1: '\e987';
$sc-icon-table-path2: '\e988';
$sc-icon-table-path3: '\e989';
$sc-icon-table-path4: '\e98a';
$sc-icon-table-path5: '\e98b';
$sc-icon-table-path6: '\e98c';
$sc-icon-table-path7: '\e98d';
$sc-icon-table-path8: '\e98e';
$sc-icon-table-path9: '\e98f';
$sc-icon-table-path10: '\e990';
$sc-icon-table-path11: '\e991';
$sc-icon-table-path12: '\e992';
$sc-icon-user1: '\e993';
$sc-icon-wine1: '\e994';
$sc-icon-broth_spoon: '\e995';
$sc-icon-butter_knife: '\e996';
$sc-icon-dessert_fork: '\e997';
$sc-icon-dessert_knife: '\e998';
$sc-icon-dessert_spoon: '\e999';
$sc-icon-fish_fork: '\e99a';
$sc-icon-fish_knife: '\e99b';
$sc-icon-meat_knife: '\e99c';
$sc-icon-pasta_fork: '\e99d';
$sc-icon-soup_spoon: '\e99e';
$sc-icon-steak_knife: '\e99f';
$sc-icon-ballon: '\e9a0';
$sc-icon-borgagna: '\e9a1';
$sc-icon-coppa: '\e9a2';
$sc-icon-flute: '\e9a3';
$sc-icon-gran_ballon: '\e9a4';
$sc-icon-renato: '\e9a5';
$sc-icon-sautern: '\e9a6';
$sc-icon-tulipano: '\e9a7';
$sc-icon-tulipano_piccolo: '\e9a8';
$sc-icon-eggs: '\e9a9';
$sc-icon-fruits: '\e9aa';
$sc-icon-gluten: '\e9ab';
$sc-icon-lacteal: '\e9ac';
$sc-icon-shelfish: '\e9ad';
