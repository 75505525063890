@use '../../../../../../../style/modules/functions' as *;
@use '../../../../../../../style/modules/commons' as *;
@use '../../../../../../../style/modules/variables' as *;
@use '../../../../../../../style/modules/mixins' as *;

.akt-recipes-bottom-text {
    margin-top: 21px; // 37px - 16px from margin bottom of tags
    margin-bottom: 67px;

    &__title {
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
    }

    &__description {
        position: relative;

        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: 0.2px;
        margin-top: 8px;
        padding-bottom: 40px;

        a {
            text-decoration: underline;
            color: var(--color-dark-yellow);
        }

        ul {
            display: block;
            list-style-type: disc;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            padding-inline-start: rem(40px);
            unicode-bidi: isolate;

            margin-bottom: 1rem;
            &:last-child {
                margin-bottom: 0;
            }
        }

        h1 {
            font-size: 2.5rem;
        }

        h2 {
            font-size: 2rem;
        }

        h3 {
            font-size: 1.75rem;
        }

        h4 {
            font-size: 1.5rem;
        }

        h5 {
            font-size: 1.25rem;
        }

        h6 {
            font-size: 1rem;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-weight: 500;
            line-height: 1.2;
            color: inherit;

            margin-bottom: rem(8px);
            &:last-child {
                margin-bottom: 0;
            }
        }

        p {
            margin-bottom: 1rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
