@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.st16-video {
    position: relative;
    display: block;
    overflow: hidden;
    background-color: black;
    display: flex;
    align-items: center;
    width: 100%;

    &__placeholder {
        width: 100%;
    }

    &__video {
        max-height: 100%;
    }
}