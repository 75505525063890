@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use "sass:map";

.ss6-anchors {
    width: 100%;
    overflow-x: auto;
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
    border-bottom: rem(1px) solid rgb(75, 58, 51, 0.32);
    transition: top 0.8s ease-in-out;

    @include respond-above(l) {
        flex-direction: column;
        max-width: #{rem(map.get($containers, 'xl'))};
        padding: 0 rem($container-wpad);
        margin: 0 auto;
    }

    &--fixed {
        position: fixed;
        top: 0;
        left: 0;
        padding-top: rem(20px);
        max-width: unset;
        width: 100vw; // viewport width
        background-color: white;
        z-index: 9;
    }
    &--belowHeader {
        top: rem($header-height);

        @include respond-above(l) {
            top: rem($header-height-dsk);
        }
    }

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }

    &__list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: rem(16px);
    }

    &__link {
        white-space: nowrap;
        display: inline;
        text-transform: uppercase;
        color: var(--color-black);
        opacity: 0.5;
        font-weight: 500;
        margin: 0 rem(30px);

        &:hover,
        &:active,
        &:visited,
        &.active {
            text-decoration: underline;
            text-underline-offset: rem(16px);
            text-decoration-color: var(--color-brown);
            text-decoration-thickness: rem(3px);
            color: var(--color-black);
            opacity: 1;
            font-weight: 600;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
        margin-right: rem(5px);

        @include respond-above(l) {
            margin-right: 0;
        }
    }
}
