@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;


.ss2-breadcrumb {
    @extend %container;

    margin-bottom: rem(21px);
    @include respond-above(l) {
        margin-bottom: rem(30px);
    }

    height: rem(17px);

    &__navList {
        display: flex;
        justify-content: flex-start;
        list-style: none;
        padding-inline-start: 0;
        margin-bottom: 0;
        overflow-x: auto;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
            scrollbar-width: none;  /* Firefox */

        @include respond-above(l) {
            overflow-x: unset;
        }
    }

    &__navList::-webkit-scrollbar {
           display: none;           /* Safari and Chrome */
     }

    &__navItem {
        display: inline-block;
        white-space: nowrap;
        @extend %font-label;

        &:not(:last-child)::after {
            content: '>';
            padding: 0 5px 0 3px;
        }

        &:last-child {
            color: var(--color-tin);
        }
    }

    &__navItemLink {
        text-decoration: none;
        color: var(--color-black);

        &:hover {
            color: var(--color-dark-yellow);
        }
    }

    &__navItem:last-child &__navItemLink {
        color: var(--color-tin);
    }
}
