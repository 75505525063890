@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;


.sc5-card-carousel-article {
    @extend %container;

    &__title {
        @extend %font-h3;
        font-weight: 600;
        margin-bottom: rem(28px);

        @include respond-above(l) {
            margin-bottom: rem(12px);
        }
        
        &--cursive {
            @extend %font-ced-h2;
            font-weight: normal;
            margin-bottom: rem(28px);

            @include respond-above(l) {
                margin-bottom: rem(12px);
            }
        }
    }

    &__texts {
        margin-bottom: rem(28px);

        @include respond-above(l) {
            margin-bottom: rem(40px);
        }
    }

    &__description {
        @extend %font-paragraph;
        font-size: rem(16px);
        font-weight: normal;
    }

    &__slider {
        margin-left: rem(-1 * $container-wpad);
        width: calc(100% + #{rem(2 * $container-wpad)});

        @include respond-above(l) {
            margin-left: 0;
            width: 100%;
        }

        .st6-slider__btn {
            &--prev {
                left: rem(-57px);
            }
            &--next {
                right: rem(-57px);
            }
        }
    }
}
