@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;

.sp1-c-refresh {

    @include respond-above(m){
        max-width: rem(720px);
        width: rem(720px);
    }

    @include respond-above(l){
        max-width: rem(794px);
        width: rem(794px);
    }

    &__close {
        @extend %buttonReset;
        position: absolute;
        right: rem(23px);
        top: rem(27px);
        font-size: rem(20px);
        cursor: pointer;
    }

    &__content {
        padding-top: rem(48px);
        padding-bottom: rem(40px);
        padding-left: rem(20px);
        padding-right: rem(20px);
    }

    &__title {
        
        @extend %font-h3;
        font-weight: 600;
        margin: 0;
        text-align: left;
        max-width: rem(453px);
    }

    &__separator {
        color: var(--color-dark-yellow);
        background-color: var(--color-dark-yellow);
    }

    &__text a,
    &__infoText a {
        color: var(--color-link-blue);
        font-weight: 600;
        text-decoration: underline;
    }

    &__bottom {
        margin-top: rem(77px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include respond-above(m){
            flex-direction: row;
        }
    }

    &__infoText {
        max-width: rem(385px);
        font-size: rem(14px);
        @extend %font-paragraph
    }

    &__button {
        width: rem(328px);
        height: rem(51px);
        margin: 0 0 rem(7px) rem(10px);
        color: var(--color-dark-yellow);
        background-color: var(--color-dark-yellow);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        box-shadow: -10px 7px 0px 0px alpha(--color-dark-yellow, 0.29);

        @include respond-above(l) {
            width: rem(256px);
        }
    }

    &__buttonLabel {
        
        font-size: rem(14px);
        font-weight: 600;
        line-height: rem(14px);
        text-decoration: none;
        color: var(--color-black);
        text-align: center;
        letter-spacing: rem(0.5px);
    }
}
