@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use '../../../../../../style/modules/themes' as *;

:root,
html {
    @include default-theme;
    color: var(--color-black);
    @extend %font-body;
    font-size: rem(14px);
    line-height: rem(20px);
    letter-spacing: rem(0.19px);
    font-weight: 400;
    font-size: $text-base-size;
    font-weight: normal;
    font-style: normal;
    letter-spacing: rem(0.2px);
    scroll-behavior: smooth;
}

.sp1-page {
    overflow-x: hidden;
    background-color: var(--color-white);
    color: var(--color-black);
    overflow-y: scroll;

    &__content {
        &--spaced {
            margin-top: rem($header-height + 26);
            @include respond-above(l) {
                margin-top: rem($header-height-dsk + 26);
            }
        }

        /** ONLY FOR HOMEPAGE */
        &--spacedPerfect {
            margin-top: rem($header-height);
            @include respond-above(l) {
                margin-top: rem($header-height-dsk);
            }
        }
    }

    .ss4-masthead--filters ~ &__content--spaced {
        margin-top: rem($header-height + 26);
        @include respond-above(l) {
            margin-top: rem($header-height-dsk + $filters-height + 26);
        }
    }

    .ss4-masthead--filtersDouble ~ &__content--spaced {
        margin-top: rem($header-height + $filters-height + 26);
        @include respond-above(l) {
            margin-top: rem($header-height-dsk + $filters-height * 2 + 26);
        }
    }

    .ss4-masthead--search.ss4-masthead--filters ~ &__content--spaced {
        @include respond-above(l) {
            margin-top: rem($header-height-dsk + $filters-height + 230);
        }
    }

    .ss4-masthead--search.ss4-masthead--filtersDouble ~ &__content--spaced {
        margin-top: rem(220px);
        @include respond-above(l) {
            margin-top: rem($header-height-dsk + $filters-height * 2 + 230 + 26);
        }
    }

    &.block-scroll {
        overflow-y: hidden;
    }
}
