@use '../../../../../../../style/modules/functions' as *;
@use '../../../../../../../style/modules/commons' as *;
@use '../../../../../../../style/modules/variables' as *;
@use '../../../../../../../style/modules/mixins' as *;

.akt-recipes-ingredients {
    &--container {
        @extend %container;
    }
    margin-top: rem(22px);
    overflow: visible;

    @include respond-above(l) {
        margin-top: rem(38px);
    }

    &__background {
        background-image: url('../../../../../../../resources/saporie/images/bg-square.png');
        padding-top: rem(22px);
        padding-bottom: rem(22px);
        overflow: visible;
        padding-left: $container-wpad;
        margin-left: -$container-wpad;
        padding-right: $container-wpad;
        margin-right: -$container-wpad;

        @include respond-above(l) {
            padding-left: 70%;
            margin-left: -70%;
            padding-right: unset;
            margin-right: unset;
            padding-top: rem(30px);
            padding-bottom: rem(55px);
        }
    }

    &__title {
        font-size: rem(24px);
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
    }

    &__carousel {
        overflow: hidden;
        margin-top: rem(30px);
        max-width: rem(335px);

        @include respond-above(m) {
            max-width: 100%;
        }
    }

    &__track {
        display: flex;
        flex-wrap: nowrap;
        transition-property: transform;
        box-sizing: content-box;

        @include respond-above(l) {
            flex-wrap: wrap;
            justify-content: flex-start;
        }
    }

    &__slide {
        position: relative;
        max-width: 33%;
        flex-shrink: 0;
        transition-property: transform;

        @include respond-above(l) {
            &:not(:last-child) {
                margin-right: rem(20px);
            }
        }
    }

    &__slideContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__allergenicText {
        font-size: rem(12px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        margin-bottom: 0;
        margin-top: rem(5px);
    }

    &__pagination {
        display: flex;
        justify-content: center;
        margin-top: rem(24px);

        @include respond-above(l) {
            display: none;
        }

        &--hidden {
            display: none;
        }
    }

    &__bullet {
        width: rem(10px);
        height: rem(10px);
        border-radius: 50%;
        opacity: 0.39;
        background-color: rgba(76, 58, 51, 0.5);

        &:not(:last-child) {
            margin-right: rem(10px);
        }

        &--active {
            opacity: 1;
            background-color: #4c3a33;
        }
    }

    &__list {
        font-size: rem(14px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.57em;
        letter-spacing: rem(0.2px);
        margin-top: rem(40px);

        ul {
            list-style: none;
            margin-bottom: 0;
            padding-inline-start: 0;
        }

        a {
            text-decoration: none;
            color: var(--color-dark-yellow);
        }

        @include respond-above(l) {
            display: flex;
            justify-content: space-evenly;

            &--left,
            &--right {
                width: 50%;
            }
        }
    }
}

/* when no banner in between is set */
.akt-recipes-ingredients + .akt-recipes-procedure {
    margin-top: rem(30px);
}
