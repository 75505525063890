@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;


.sc20-glossary-item {
    @extend %container;

    &__hr {
        border-top: solid 1px var(--color-salmon-rose);
    }

    &__media {
        height: rem(200px);
        margin-bottom: rem(10px);

        @include respond-above(l) {
            height: rem(500px);
            margin-bottom: rem(15px);
        }
    }

    &__texts {
        display: flex;
        flex-direction: column;

        @include respond-above(l) {
            flex-direction: row;
            align-items: flex-start;
            margin-bottom: rem(30px);
        }
    }

    &__title {
        @extend %font-h5;
        font-weight: 600;
        width: rem(150px);
        margin-bottom: rem(10px);

        @include respond-above(l){
            margin-bottom: 0;
            margin-right: rem(22px);
        }
    }

    &__description {
        @extend %font-paragraph;
        width: 100%;

        @include respond-above(l) {
            width: rem(818px);
        }
    }

    &--hide {
        display: none;
    }
}
