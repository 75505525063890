@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;


.ss8-poi {
    @extend %container;

    @include respond-above(l) {
        margin-top: 0;
    }

    &__breadcrumbstyle {
        margin-bottom: 0;
        @include respond-above(l) {
            margin-bottom: rem(31px);
        }
    }

    &__title {
        font-weight: 600;
        @extend %font-h2;
        margin-bottom: rem(27px);
        display: block;
        margin: rem(21px) rem(48px) 0 0;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-bottom: rem(27px);

        @include respond-above(l) {
            margin-top: 0;
            font-size: rem(48px);
            line-height: rem(48px);
            width: 80%;
        }
    }

    &__image, &__video {
        width: 100%;
        height: rem(200px);
        margin-bottom: rem(24px);

        @include respond-above(l) {
            width: rem(720px);
            height: rem(500px);
        }
    }

    &__ctas {
        display: flex;
        flex-direction: row;
              
        @include respond-above(l) {
            justify-content: flex-end;
            margin-bottom: rem(30px);
        }
    }

    &__text {
        @extend %font-paragraph;

        @include respond-above(l) {
            max-width: rem(330px);
            margin-right: rem(40px);
        }
    }

    &__texts {
        display: flex;
        flex-direction: column;
        margin-bottom: rem(30px);

        @include respond-above(l) {
            margin-bottom: 0;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;

        @include respond-above(l) {
            flex-direction: row;
        }
    }

    &__info {
        justify-content: flex-end;
    }
    
}
