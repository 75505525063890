@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;


.sc12-video {
    &--normal {
        width: inherit;
        height: inherit;
    }

    &--banner {
        height: rem(211px);

        @include respond-above(l) {
            height: rem(500px);
        }
    }

    &--h600 {
        height: rem(211px);

            @include respond-above(l) {
                height: rem(600px);
            }
        }
}
