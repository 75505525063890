@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;


.sc8-widget-territory {
    &__innerContent {
        @extend %container;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

        @include respond-above(l) {
            flex-direction: row-reverse;
        }

        &--flipContent {
            flex-direction: column; 

            @include respond-above(l) {
                flex-direction: row;
            }
        }
    }

    &__cursive {
        @extend %font-ced-h2;
        line-height: rem(36px);
        max-width: rem(341px);
        margin-bottom: rem(29px);
    }

    &__media {
        width: 100%;
        height: 20.375rem;
        margin-bottom: rem(100px);

        @include respond-above(l) {
            width: 100%;
            height: rem(440px);
            margin-bottom: 0;
        }

        &--flipContent {
            margin-bottom: 0; 
        }
    }

    &__texts {
        display: flex;
        flex-direction: column;
        
        @include respond-above(l) {
            margin-left: rem(109px);
        }

        &--flipContent {
            margin-bottom: rem(100px);

            @include respond-above(l) {
                margin-left: 0; 
                margin-bottom: 0;  
                margin-right: rem(109px);  
            }
        }
    }

    &__breadcrumb {
        @extend %font-label;
        font-weight: 500;
        margin: 0 0 rem(16px) 0;
        text-align: left;
    }

    &__title {
        
        font-size: rem(24px);
        font-weight: 600;
        line-height: normal;
        width: rem(341px);

        @include respond-above(l) {
            font-size: rem(32px);
            line-height: 1.13;
        }
    }

    &__description { 
        margin-top: rem(20px);
        margin-bottom: 0;
        width: rem(341px);
    }

    &__button {
        width: rem(328px);
        height: rem(51px);
        margin: rem(40px) 0 rem(7px) rem(10px);
        color: var(--color-dark-yellow);
        background-color: var(--color-dark-yellow);
        display: flex;
        justify-content:center;
        align-items: center;
        position: relative;
        box-shadow: -10px 7px 0px 0px alpha(--color-dark-yellow, 0.29);

        @include respond-above(l) {
            width: rem(256px);
        }
    }

    &__buttonLabel{
        
        font-size: rem(14px);
        font-weight: 600;
        line-height: rem(14px);
        text-decoration: none;
        color: var(--color-black);
        text-align: center;
        letter-spacing: rem(0.5px);
    }

}
