@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;

.sc1-separator {
    @extend %container;

    &__hr {
        border-top: solid 1px var(--color-salmon-rose);
    }
}
