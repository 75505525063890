@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;


.sc17-widget-chef {
    @extend %container;

    &__texts {
        display: flex;
        flex-direction: column;
        margin-bottom: rem(24px);

        @include respond-above(l) {
            margin-bottom: rem(40px);
        }
    }

    &__title {
        @extend %font-h3;
        font-weight: 600;
    }

    &__description { 
        @extend %font-paragraph;
        font-size: rem(16px);
        font-weight: normal;
    }

    &__card-container {
        margin-bottom: rem(10px);

        @include respond-above(l) {
            margin-bottom: 0;
        }
    }

    &__sliderCenter {
        .st6-slider__track {
            @include respond-above(l) {
                justify-content: center;
            }
        }
    }


}
