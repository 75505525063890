@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;

.ss11-collection {
    @include respond-above(l) {
        margin-top: 0;
    }

    &__breadcrumbstyle {
        margin-bottom: 0;
        @include respond-above(l) {
            margin-bottom: rem(31px);
        }
    }

    &__title {
        font-weight: 600;
        @extend %font-h2;
        margin-bottom: rem(14px);
        display: block;
        margin: rem(21px) rem(48px) 0 0;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-bottom: 0;

        @include respond-above(l) {
            margin: 0 0 0 0;
            font-size: rem(48px);
            line-height: rem(48px);
            width: 80%;
        }
    }

    &__image,
    &__video {
        height: rem(211px);
        margin-top: rem(23px);
        margin-bottom: rem(70px);

        @include respond-above(l) {
            height: rem(500px);
        }
    }

    &__top {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @extend %container;

        @include respond-above(l) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__text {
        @extend %font-paragraph;
        @extend %container;
        width: 100%;
        @include respond-above(l) {
            width: rem(720px);
        }
    }

    &__ctas {
        display: flex;
        flex-direction: row;
    }

    &__noNottomMargin {
        margin-bottom: 0;
    }

    &__partnershipText {
        max-width: rem(600px);
        width: fit-content;
        padding: rem(9px) rem(9px) rem(9px) rem(9px);
        text-align: left;
        @extend %font-paragraph;
        margin-bottom: 0;
        color: var(--color-black);
        background-color: var(--color-salmon-rose);
        margin-bottom: rem(30px);

        @include respond-above(l) {
            margin-left: rem(140px);
            margin-right: auto;
        }
    }

    &__partnershipLabel {
        font-weight: 600;
        text-decoration: underline;
        color: var(--color-black);
    }
}
