@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;


.sl1-layout {
    &__item {
        &--spacedTopNone {
            padding-top: 0;
        }

        &--spacedBottomNone {
            padding-bottom: 0;
        }

        &--spacedTop {
            padding-top: rem(24px);
        }

        &--spacedTop2x {
            padding-top: rem(48px);
        }

        &--spacedTop3x {
            padding-top: rem(72px);
        }

        &--spacedBottom {
            padding-bottom: rem(24px);
        }

        &--spacedBottom2x {
            padding-bottom: rem(48px);
        }

        &--spacedBottom3x {
            padding-bottom: rem(72px);
        }

        &--bgOffWhite {
            background-color: var(--color-ex-slight-off-white);
        }

        &--bgOffWhiteCut {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0) 54px, var(--color-ex-slight-off-white) 54px);
        }

        &--bgLightYellow {
            background-color: alpha(--color-dark-yellow, 0.15);
        }
        
        &--bgLightYellowCut {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0) 54px, alpha(--color-dark-yellow, 0.15) 54px);
        }

        &--bgNearlyWhite {
            background-color: var(--color-nearly-white);
        }

        &--bgNearlyWhiteCut {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0) 54px,  var(--color-nearly-white) 54px);
        }

        &--bgLightRose {
            background-color: var(--color-light-rose);
        }

        &--bgLightRoseCut {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0) 54px, var(--color-light-rose) 54px);
        }
    }
}
