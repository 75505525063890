@use './variables' as *;
@use './mixins' as *;
@use './functions' as *;
@use "sass:map";

//
//  CONTAINER
//––––––––––––––––––––––––––––––––––––––––––––––––––

%container {
    width: 100%;
    padding: 0 rem($container-wpad);
    margin: 0 auto;

    @include respond-above(m) {
        max-width: #{rem(map.get($containers, 'm'))};
    }

    @include respond-above(l) {
        max-width: #{rem(map.get($containers, 'l'))};
    }

    @include respond-above(xl) {
        max-width: #{rem(map.get($containers, 'xl'))};
    }
}

//
//  COMPONENT RESET
//––––––––––––––––––––––––––––––––––––––––––––––––––

%headingReset {
    margin-bottom: 0;
}

%buttonReset {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    border-radius: 0;
    text-align: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;

    /* Blue outline removal (not very accessible) */
    &:focus {
        outline: none;
    }

    /* Remove excess padding and border in Firefox 4+ */
    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
}

//
//  FONTS FAMILY
//––––––––––––––––––––––––––––––––––––––––––––––––––
%font-body {
    font-family: 'Poppins';
}

%font-display {
    font-family: 'Cedarville Cursive';
}

//
//  FONTS STYLE
//––––––––––––––––––––––––––––––––––––––––––––––––––

%font-h1 {
    font-size: rem(48px);
    line-height: rem(52px);
}

%font-h1Resp {
    font-size: rem(24px);
    line-height: rem(30px);

    @include respond-above(l) {
        font-size: rem(48px);
        line-height: rem(52px);
    }
}

%font-h2 {
    font-size: rem(32px);
    line-height: rem(36px);
}

%font-h3 {
    font-size: rem(24px);
    line-height: rem(30px);
}

%font-h4 {
    font-size: rem(20px);
    line-height: rem(26px);
}

%font-h5 {
    font-size: rem(18px);
    line-height: rem(22px);
}

%font-h6 {
    font-size: rem(16px);
    line-height: rem(22px);
}

%font-paragraph {
    font-size: rem(14px);
    line-height: rem(20px);
    letter-spacing: rem(0.19px);
}

%font-description {
    font-size: rem(13px);
    line-height: 1.54;
    letter-spacing: rem(0.19px);
}

%font-label {
    font-size: rem(12px);
    line-height: rem(18px);
}

%font-hyperlink {
    font-size: rem(12px);
    line-height: rem(18px);
}

%font-ced-h2 {
    @extend %font-display;
    font-size: rem(32px);
    line-height: rem(36px);
}

%font-ced-h3 {
    @extend %font-display;
    font-size: rem(24px);
    line-height: rem(30px);
}

%font-ced-h4 {
    @extend %font-display;
    font-size: rem(20px);
    line-height: rem(26px);
}

%font-ced-label {
    @extend %font-display;
    font-size: rem(18px);
    line-height: rem(18px);
}

%font-link {
    font-size: rem(14px);
    line-height: rem(14px);
}

%font-18Fix {
    font-size: rem(18px);
    line-height: rem(24px);
}

%font-20Fix {
    font-size: rem(20px);
    line-height: rem(24px);
}

%font-20Resp {
    font-size: rem(16px);
    line-height: rem(20px);

    @include respond-above(l) {
        font-size: rem(20px);
        line-height: rem(24px);
    }
}

%font-24Resp {
    font-size: rem(20px);
    line-height: rem(28px);

    @include respond-above(l) {
        font-size: rem(24px);
        line-height: rem(32px);
    }
}

%font-26Resp {
    font-size: rem(24px);
    line-height: rem(28px);

    @include respond-above(l) {
        font-size: rem(26px);
        line-height: rem(30px);
    }
}

%font-26Fix {
    font-size: rem(26px);
    line-height: rem(30px);
}

%font-32Resp {
    font-size: rem(28px);
    line-height: rem(32px);

    @include respond-above(l) {
        font-size: rem(32px);
        line-height: rem(36px);
    }
}

%font-32Resp24 {
    font-size: rem(24px);
    line-height: rem(28px);

    @include respond-above(l) {
        font-size: rem(32px);
        line-height: rem(36px);
    }
}

%font-48Fix {
    font-size: rem(48px);
    line-height: rem(48px);
}

%font-48Resp {
    font-size: rem(32px);
    line-height: rem(36px);

    @include respond-above(l) {
        font-size: rem(48px);
        line-height: rem(52px);
    }
}

%font-48Alt {
    font-size: rem(32px);
    line-height: rem(26px);

    @include respond-above(l) {
        font-size: rem(48px);
        line-height: rem(52px);
    }
}

%font-120Resp {
    font-size: rem(72px);
    line-height: rem(72px);

    @include respond-above(l) {
        font-size: rem(120px);
        line-height: rem(120px);
    }
}

%font-label-v2 {
    font-size: rem(14px);
    line-height: rem(18px);
}

// BASIC STYLES

@mixin checkbox {
    cursor: pointer;
    position: relative;
    padding-left: rem(24px);
    display: inline-block;
    line-height: rem(16px);
    margin-bottom: 0;

    .checkbox {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
    }

    .label {
        @extend %font-paragraph;
        font-weight: 500;
        line-height: rem(16px);

        &::before {
            content: '';
            position: absolute;
            width: rem(16px);
            height: rem(16px);
            border: 1px solid var(--color-dark-yellow);
            background-color: var(--color-white);
            vertical-align: middle;
            left: 0;
            top: 0;
            display: inline-block;
        }

        &::after {
            @extend %sc-icon-base;
            content: $sc-icon-tick;
            position: absolute;
            top: 0;
            vertical-align: middle;
            left: 0;
            display: none;
            color: var(--color-dark-yellow);
            font-size: rem(12px);
            line-height: rem(16px);
            left: 2px;
        }
    }

    .checkbox:checked + .label::after {
        display: block;
    }
}

%sc-icon-base {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
