@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;


.sc11-widget-products {
    @extend %container;

    &__texts {
        display: flex;
        flex-direction: column;
        margin-bottom: rem(24px);
        
        @include respond-above(l) {
            margin-bottom: rem(40px);
        }
    }

    &__title {
        @extend %font-h3;
    }

    &__description { 
        @extend %font-paragraph;
    }

    &__inner-content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__cards {
        display: flex;
        flex-direction: column;

        @include respond-above(l) {
            flex-direction: row;
        }
    }

    &__card-container {
        margin-bottom: rem(10px);

        @include respond-above(l) {
            margin-bottom: 0;
        }
    }


}
