@use '../../../../../../../style/modules/functions' as *;
@use '../../../../../../../style/modules/commons' as *;
@use '../../../../../../../style/modules/variables' as *;
@use '../../../../../../../style/modules/mixins' as *;

.akt-recipes-intro {
    margin-top: rem(19px);

    &__richtext {
        position: relative;

        font-size: rem(14px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57rem;
        letter-spacing: rem(0.2px);
        padding-bottom: rem(20px);

        a {
            text-decoration: underline;
            color: var(--color-dark-yellow);
        }

        ul {
            display: block;
            list-style-type: disc;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            padding-inline-start: rem(40px);
            unicode-bidi: isolate;

            margin-bottom: 1rem;
            &:last-child {
                margin-bottom: 0;
            }
        }

        h1 {
            font-size: 2.5rem;
        }

        h2 {
            font-size: 2rem;
        }

        h3 {
            font-size: 1.75rem;
        }

        h4 {
            font-size: 1.5rem;
        }

        h5 {
            font-size: 1.25rem;
        }

        h6 {
            font-size: 1rem;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-weight: 500;
            line-height: 1.2;
            color: inherit;
        }

        p {
            margin-bottom: 1rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    a {
        text-decoration: underline;

        font-size: rem(14px);
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: rem(0.2px);
    }
}
