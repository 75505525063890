@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.st26-sharelink {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    width: rem(164px);
    padding: rem(16px);
    background-color: white;
    box-shadow: 0 8px 16px -4px #00000033, 0 0 1px 0 #00000033;
    border-radius: 3px;
    top: 0;

    &--show {
        visibility: visible;
        pointer-events: all;
        z-index: 1;
    }

    & > div:first-child {
        position: absolute;
        top: -9px;
        left: 30%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8px 10px 8px;
        border-color: transparent transparent white transparent;
    }

    &__heading {
        margin-bottom: rem(20px);
    }

    &__heading,
    &__shareOption > a {
        font-weight: bold;
        font-size: rem(12px);
        line-height: rem(16px);
        color: #333;
    }
    &__shareOption {
        display: flex;
        align-items: center;
        gap: rem(8px);

        & > div:first-child {
            display: inline-block;
            width: 20px;
            height: 20px;
            background-size: 20px;
        }
    }
    &__shareOption[data-sharer='facebook'] > div:first-child {
        background-image: url('#{$resourcesFolder}/saporie/icons/social/facebook.svg');
    }
    &__shareOption[data-sharer='twitter'] > div:first-child {
        background-image: url('#{$resourcesFolder}/saporie/icons/social/twitter_share.svg');
    }
    &__shareOption[data-sharer='linkedin'] > div:first-child {
        background-image: url('#{$resourcesFolder}/saporie/icons/social/linkedin.svg');
    }
    &__shareOption[data-sharer='whatsapp'] > div:first-child {
        background-image: url('#{$resourcesFolder}/saporie/icons/social/whatsapp.svg');
    }
    &__shareOption[data-sharer='pinterest'] > div:first-child {
        background-image: url('#{$resourcesFolder}/saporie/icons/social/pinterest_share.svg');
    }
    &__shareOption[data-sharer='email'] > div:first-child {
        background-image: url('#{$resourcesFolder}/saporie/icons/social/email.svg');
    }

    &__shareOptions {
        display: flex;
        flex-flow: column;
        gap: rem(20px);
    }
}
