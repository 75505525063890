@use '../../../../../../../style/modules/functions' as *;
@use '../../../../../../../style/modules/commons' as *;
@use '../../../../../../../style/modules/variables' as *;
@use '../../../../../../../style/modules/mixins' as *;

.akt-recipes-save-share {
    margin-top: 70px;
    display: none;
    flex-flow: row nowrap;
    justify-content: space-evenly;

    &--mobile {
        display: flex;
        @include respond-above(m) {
            display: none;
        }
    }

    &__save,
    &__share {
        padding: 17px 43px 19px 43px;
        position: relative;
        background-color: #fdebc9;
        height: 50px;
        text-align: center;
        
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;

        & > div:first-of-type {
            display: flex;
            flex-flow: column;
            justify-content: center;
            width: 100%;
            height: inherit;
            position: absolute;
            top: -7px;
            right: -7px;
            background-color: #f7c260;
        }
    }
    &__save {
        max-width: 180px;
        width: 100%;
    }
    &__share {
        width: 81px;
    }

    &__saveCta,
    &__shareCta {
        cursor: pointer;
    }

    &__shareContent {
        position: absolute;
        top: 90%;
        left: -113px;

        @include respond-above(l) {
            top: -21px;
            left: 16px;
        }
    }
}

/* vertical spacing above tags */
.akt-recipes-save-share ~ .akt-recipes-tags {
    margin-top: rem(30px);
}
