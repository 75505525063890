@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.st8-menu-mobile {
    position: absolute;
    top: rem($header-height);
    height: calc(100vh - #{rem($header-height)});
    background: var(--color-white);
    width: 100%;

    pointer-events: none;
    left: -100vw;
    transition: left 0.5s ease-in-out;
    padding-top: rem(17px);

    display: flex;
    flex-direction: column;

    @include respond-above(l) {
        display: none;
    }

    &--open {
        left: 0;
        pointer-events: all;
    }

    &__topper {
        @extend %container;
        margin-bottom: rem(48px);
        padding-left: rem(35px);
        padding-right: rem(35px);
    }

    &__searchForm {
        position: relative;
        display: block;
        border-bottom: solid 1px var(--color-ground-brown);
        max-width: rem(900px);
        margin: 0px auto;
    }

    &__searchSubmit {
        @extend %buttonReset;
        width: rem(40px);
        height: rem(40px);
        overflow: hidden;
        position: absolute;
        border: none;
        cursor: pointer;
        bottom: 0;
        right: 0;
        background: url(../../../../../resources/saporie/images/icons/summary/search.svg) center center no-repeat;
    }

    &__searchInput {
        
        font-weight: 300;
        width: 100%;
        height: rem(40px);
        padding-right: rem(40px);
        padding-left: rem(40px);
        text-align: center;
        background: transparent;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: none;
        color: var(--color-ground-brown);
        font-size: rem(18px);
    }

    &__links {
        left: -100vw;
        position: absolute;
        top: rem(-1 * $header-height);
        bottom: 0;
        width: 100%;
        background-color: var(--color-white);
        transition: left 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        z-index: 2;

        &--show {
            left: 0;
        }

        &--root {
            left: 0;
            position: relative;
            top: 0;
        }
    }

    &__linksBackCnt {
        @extend %container;
        padding-left: rem(35px);
        padding-right: rem(35px);
        height: rem($header-height);
        width: 100%;
        display: flex;
        align-items: center;
        flex: 1 0 auto;

        &--scrolled {
            box-shadow: alpha(--color-black, 0.2) 0px 3px 6px;
        }
    }

    &__scroller {
        height: calc(100% - #{rem($header-height)});
        overflow-y: auto;
        display: flex;
        flex-direction: column;
    }

    &__links--root &__scroller {
        height: 100%;
    }

    &__linksBack {
        @extend %buttonReset;
        @extend %font-paragraph;
        font-weight: 600;

        &::before {
            content: '';
            background-image: url(../../../../../resources/saporie/images/black_left_arrow.svg);
            display: inline-block;
            background-size: 100% 100%;
            transform: rotate(-180deg);
            margin-right: rem(20px);
            background-repeat: no-repeat;
            background-position: center center;
            width: rem(15px);
            height: rem(18px);
            vertical-align: text-bottom;
        }
    }

    &__slider {
        .st6-slider__paginator {
            display: none;
        }
    }

    &__slide {
        display: block;
        width: 100%;
    }

    &__slideImg {
        width: 100%;
        height: rem(191px);
        object-fit: cover;
    }

    &__slideImgTitle {
        @extend %headingReset;
        @extend %font-h4;
        font-weight: 600;
        position: absolute;
        bottom: rem(14px);
        left: rem(28px);
        line-height: rem(30px);
        color: var(--color-black);

        max-width: rem(278px);

        & > span {
            background: alpha(--color-white, 0.8);
            box-shadow: 10px 0 0 alpha(--color-white, 0.8), -10px 0 0 alpha(--color-white, 0.8);
        }
    }

    &__linksBackCnt + &__items {
        margin-top: rem(5px);
    }

    &__slider + &__items {
        margin-top: rem(25px);
    }

    &__items {
        @extend %container;
        padding-left: rem(35px);
        padding-right: rem(35px);
        flex: 1 1 auto;

        list-style: none;
        li {
            @extend %font-h6;
            font-weight: 500;
            margin-bottom: rem(30px);
        }

        a {
            color: var(--color-black);
            font-weight: 600;
        }
    }

    &__linksNext {
        @extend %buttonReset;
        display: flex;
        justify-content: space-between;
        width: 100%;

        span {
            @extend %font-h6;
            font-weight: 500;
        }
    }

    &__arrow {

        &::after {
            content: '';
            background-image: url(../../../../../resources/saporie/images/black_left_arrow.svg);
            display: block;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center center;
            width: rem(15px);
            height: rem(18px);
        }
    }

    &__evidence {
        padding-top: rem(17px);
        padding-bottom: rem(26px);
        background-color: var(--color-ex-slight-off-white);
    }

    &__evidenceSlide {
        display: flex;
        align-items: center;
    }

    &__evidenceSlideImg {
        width: rem(89px);
        height: rem(60px);
        object-fit: cover;
        margin-right: rem(9px);
    }

    &__evidenceSlideTitle {
        @extend %font-label;
        font-weight: 600;
        width: rem(130px);
        color: var(--color-black);
    }

    &__sep {
        width: 100%;
        border-top: solid 1px var(--color-salmon-rose);
        margin-bottom: rem(30px);
        margin-top: 0;
    }

    &__liSep {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__item {
        display: flex;
        justify-content: space-between;
    }
}
