@use '../../../../../../../style/modules/functions' as *;
@use '../../../../../../../style/modules/commons' as *;
@use '../../../../../../../style/modules/variables' as *;
@use '../../../../../../../style/modules/mixins' as *;

@mixin titleCommons {
    
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.akt-recipes-procedure {
    &--container {
        @extend %container;
    }

    &__title {
        @include titleCommons();
        font-size: rem(24px);
        font-weight: 600;
        margin-bottom: rem(26px);
    }

    &__stepsList {
        list-style: none;
        padding-left: 0;
    }

    &__step {
        margin-top: rem(21px);
        padding-bottom: rem(21px);
        border-bottom: rem(1px) solid #fceac8;

        &--roseSep {
            border-bottom: rem(1px) solid var(--color-salmon-rose);
        }

        &:first-child {
            margin-top: 0;
        }

        p {
            margin-bottom: 0;
        }

        @include respond-above(l) {
            margin-top: rem(25px);
            padding-bottom: rem(25px);

            &:last-child {
                border-bottom: unset;
            }
        }
    }

    &__stepMedia {
        height: rem(223px);
        margin-bottom: rem(10px);

        @include respond-above(l) {
            height: rem(479px);
            margin-bottom: rem(15px);
        }

        &--image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
        }

        /* embedded youtube video height */
        &--video {
            height: rem(223px);

            @include respond-above(l) {
                height: rem(479px);
            }
        }
    }

    &__stepText {
        @include respond-above(l) {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
        }

        &--title {
            @include titleCommons();
            font-size: rem(18px);
            font-weight: 600;
            width: poc(134, 684);

            @include respond-above(l) {
            }
        }

        &--description {
            
            font-size: rem(14px);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: rem(21px);
            letter-spacing: rem(0.2px);
            margin-top: rem(10px);
            margin-right: rem(37px);
            width: poc(550, 684);

            @include respond-above(l) {
                margin-top: 0;
                margin-right: 0;
            }
        }
    }

    &__preparationText {
        
        font-size: rem(14px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: rem(0.2px);
    }
}

/* vertical spacing above tags */
.akt-recipes-procedure + .akt-recipes-tags {
    margin-top: rem(30px);
}
