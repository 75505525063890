@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;


.akt-content-double-column {
    @extend %container;
    display: grid;
    grid-template-areas: 
            "left"
            "right"
            "bottom";
    @include respond-above(l) {
        grid-template-areas: 
            "left right"
            "bottom right";
            grid-template-columns: 68% 100%;
    }

    &__left {
        grid-area: left;
        @include respond-above(l) {
            width: 95.95%;
            display: inline-block;

            &--top {
                height: fit-content;
            }
        }
    }

    &__bottom {
        grid-area: bottom;

        @include respond-above(l) {
            width: 95.95%;
            display: inline-block;
        }
    }

    &__right {
        grid-area: right;
        
        @include respond-above(l) {
            width: poc(351px,1088px);
            display: inline-block;
        }
    }
    &__banner {
        margin: 30px 0;
        @include respond-below(l){
            width: 85vw;
        }
    }
}

.akt-placeholder-text {
    padding: 15px 0 15px 0;
}

.akt-article-recipe__content {
    margin-top: rem($header-height + 26);
    @include respond-above(l) {
    margin-top: 8.5rem;
    }
}

.akt-article-recipe__breadcrumbstyle {
    @include respond-above(l) {
            margin-bottom: rem(31px);
            }
}