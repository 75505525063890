@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;


.sc18-widget-5-cards {
    @extend %container;
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: column;

    &__title {
        @extend %font-ced-h2;
        font-weight: normal;
        margin-bottom: rem(28px);
        line-height: rem(46px);
        @include respond-above(l) {
            margin-bottom: rem(12px);
        }
    }

    &__bottom {
        width: rem(1000px);
        height: 100%;
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        flex-wrap: nowrap;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
        display: none;           /* Safari and Chrome */
        }

        @include respond-above(l) {
            width: 100%;
        }

    }

    &__top {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: rem(10px);

        @include respond-below(l) {
            flex-direction: column;
        }
    }

    &__card {
        margin-right: rem(16px);

        &:last-child {
            margin-right: 0;
        }
    }

    &__recipe {
            width: 100%;
            height: rem(500px);
            @include respond-above(l) {
                width: 66%;
            }
        .sc2-recipe-in-evi {
            width: 100%;
            height: rem(500px);
        .sc2-recipe-in-evi__media {
            width: 100%;
            height: rem(500px);
        }
    }
    }

}
