@use '../../../../../../../style/modules/functions' as *;
@use '../../../../../../../style/modules/commons' as *;
@use '../../../../../../../style/modules/variables' as *;
@use '../../../../../../../style/modules/mixins' as *;

.akt-recipes-tags {
    &--container {
            @extend %container;
        }

    &__listWrapper {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        border-top: 1px solid #f49984;
        flex-flow: column;
        padding-top: 17px;

        @include respond-above(l) {
            flex-flow: row;
            padding-top: 37px;
        }
    }

    &__label {
        
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-bottom: 0;
        display: none;

        @include respond-above(l) {
            margin-right: 14px;
            display: unset;
        }
    }
    
    &__list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        list-style: none;
        padding-left: 0;
        margin-top: 10px;

        @include respond-above(l) {
            margin-top: 0;
        }
    }

    &__item {
        text-align: center;
        width: fit-content;
        padding: 2px 15px 3px 15px;
        border-radius: 16px;
        border: solid 2px #f49984;
        background-color: rgba(255, 255, 255, 0.82);
        margin-bottom: 16px;

        &:not(:last-child) {
            margin-right: 10px;
        }

        &--link {
            text-decoration: none;
            
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 18px;
            letter-spacing: normal;
            color: #000000;
        }

        a:focus, a:active {
            color: #000000;
        }
        a:hover {
            color: #F7C260;
        }
    }
}