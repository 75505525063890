@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;


.ss9-poi {
    @include respond-above(l) {
        margin-top: 0;
    }


    &__breadcrumbstyle {
        margin-bottom: 0;
        @include respond-above(l) {
            margin-bottom: rem(31px);
        }
    }

    &__title {
        font-weight: 600;
        @extend %font-h2;
        display: block;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-bottom: rem(27px);

        @include respond-above(l) {
            margin-top: 0;
            font-size: rem(48px);
            line-height: rem(48px);
        }
    }

    
    &__image, &__video {
        height: rem(211px);
        margin-top: rem(23px);
        margin-bottom: rem(40px);

        @include respond-above(l) {
            height: rem(500px);
        }
    }

    &__ctas {
        display: flex;
        flex-direction: row;
              
        @include respond-above(l) {
            justify-content: flex-end;
            margin-bottom: rem(30px);
        }
    }


    &__info {
        justify-content: center;
        @extend %container;
        margin-bottom: rem(30px);

        .st21-poi-info {
            &__goto, &__location {
                display: flex;
                flex-direction: row;
            }
        }
        @include respond-above(l) {
            justify-content: flex-end;
        }
    }

    &__top {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @extend %container;

        @include respond-above(l) {
            flex-direction: row;
            align-items: center;
        }
    }
    
}
