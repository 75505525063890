@use '../../../../../../../style/modules/functions' as *;
@use '../../../../../../../style/modules/commons' as *;
@use '../../../../../../../style/modules/variables' as *;
@use '../../../../../../../style/modules/mixins' as *;

.akt-recipes-banner-wide {
    margin-top: 40px;

    &__image {
        
        img {
            width: 100%;
            max-height: 200px;
            object-fit: cover;
            overflow: hidden;
        }
    }
}