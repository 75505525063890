@use '../../../../../../../style/modules/functions' as *;
@use '../../../../../../../style/modules/commons' as *;
@use '../../../../../../../style/modules/variables' as *;
@use '../../../../../../../style/modules/mixins' as *;

@mixin titleCommons {
    
    font-size: rem(20px);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3em;
    letter-spacing: normal;
    color: var(--color-black);
}

.akt-recipes-similar {
    @extend %container;
    z-index: 30;
    background: var(--color-white);

    &--desktop {
        display: none;
        margin-top: rem(23px);
        margin-bottom: rem(33px);
    }
    &--mobile {
        display: block;
        position: fixed;
        bottom: 0;
        opacity: 1;
        z-index: 9;
        transition: bottom 0.3s ease-in, opacity 0.4s ease-in;
        box-shadow: 0 rem(-3px) rem(4px) 0 rgba(0, 0, 0, 0.1);

        &__box {
            position: relative;
            transition: all 0.5s ease-in-out;

            &--header {
                display: flex;
                justify-content: space-between;
                pointer-events: all;
                cursor: pointer;
                height: rem(60px);
            }

            &--content {
                display: block;
                width: 100%;
                overflow-y: scroll;
                scroll-behavior: smooth;
                transition: height 0.3s ease-out, opacity 0.3s linear;
                margin-bottom: rem(10px);
                height: rem(347px);
            }

            &--close {
                position: absolute;
                height: rem(60px);
                width: 100%;
                top: 0;
                left: 0;
                cursor: pointer;
                margin-bottom: 0;
                /* INITIAL STATE OF CLOSE INPUT */
                opacity: 0;
                pointer-events: none;
            }

            &--arrowOpen {
                transform: rotate(180deg);
            }

            &--arrow {
                display: inline-block;
                content: url('../../../../../../../resources/saporie/images/down_arrow.svg');
                width: rem(14px);
            }
        }
    }
    @include respond-above(l) {
        &--desktop {
            display: block;
            padding: 0;
        }
        &--mobile {
            display: none;
        }
    }

    &__title {
        @include respond_above(l) {
            @include titleCommons();
        }
    }

    &__box {
        position: relative;
        transition: all 0.5s ease-in-out;

        &--title {
            @include titleCommons();
            margin-bottom: 0;
            width: rem(180px);
            display: inline-block;
            cursor: pointer;
            padding: rem(15px) 0 rem(16px) 0;
        }
    }

    &__recipe {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:not(:last-child) {
            padding-bottom: rem(10px);
            border-bottom: rem(1px) solid var(--color-salmon-rose);
        }
        &:not(:first-child) {
            margin-top: rem(10px);
        }

        &--right {
            display: flex;
            flex-direction: column;
            width: 11rem;
            //min-width: rem(169px);
        }

        &--left {
            width: 155px;
            height: 80px;
            margin-right: rem(19px);
        }
    }
    &--desktop &__recipe {
        &:not(:first-child) {
            margin-top: rem(32px);
        }
        &:first-child {
            margin-top: rem(27px);
        }
        &:not(:last-child) {
            padding-bottom: rem(32px);
        }

        &--left {
            width: rem(155px);
            height: rem(80px);
        }
    }

    &__recipeLink {
        
        font-size: rem(14px);
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: var(--color-black);
        margin-top: rem(4px);

        span {
            height: rem(36px);
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__recipeCollection {
        
        font-size: rem(12px);
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: var(--color-black);

        &--link {
            color: var(--color-black) !important;
            text-decoration: underline;
            padding-left: rem(3px);
            border-left: rem(1px) solid var(--color-dark-yellow);
        }
        &--link:hover {
            color: var(--color-dark-yellow) !important;
            text-decoration: underline;
        }
    }

    &__image {
        object-fit: cover;
        height: rem(72px);
        width: rem(139px);

        @include respond-above(l) {
            width: rem(151px);
        }
    }

    &--fadeOut {
        pointer-events: none;
        opacity: 0;
        bottom: rem(-55px);
        transition: bottom 0.5s ease-out, opacity 0.7s ease-out;
    }

    &__accordion {
        display: block;

        @include respond_above(l) {
            display: none;
        }
    }
}
