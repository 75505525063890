@use '../../../../../../../style/modules/functions' as *;
@use '../../../../../../../style/modules/commons' as *;
@use '../../../../../../../style/modules/variables' as *;
@use '../../../../../../../style/modules/mixins' as *;

.akt-recipes-related-articles {
    overflow: hidden;
    margin-top: rem(52px);
    margin-right: rem(-$container-wpad);

    @include respond-above(l) {
        //margin-top: 56px);
        margin-top: rem(23px);
        margin-right: 0;
    }

    &__title {
        font-size: rem(20px);
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: normal;
        margin-bottom: 0;
    }

    &__slideContainer {
        width: rem(258px);
        display: flex;
        flex-flow: column;
        margin: 0 auto;
        height: 100%;

        @include respond-above(l) {
            width: rem(351px);
            margin: 0;

            &:not(:first-child) {
                margin-top: rem(17px);
            }
        }
    }

    &__slideImage {
        object-fit: cover;
        width: rem(246px);
        height: rem(166px);

        @include respond-above(l) {
            width: rem(351px);
            height: rem(181px);
        }
    }

    &__slideText {
        display: flex;
        flex-flow: column;
        flex-grow: 1;
        max-width: rem(214px);
        margin-left: rem(16px);
        margin-top: rem(14px);

        @include respond-above(l) {
            max-width: rem(272px);
            margin-left: 0;
            margin-top: rem(16px);
        }
    }

    &__slideTop {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        flex-grow: 1;
        padding-bottom: rem(30px);
        border-bottom: rem(1px) solid rgba(75, 58, 51, 0.16);

        @include respond-above(l) {
            padding-bottom: 0;
            border-bottom: unset;
        }
    }

    &__slide {
        overflow: visible;
        width: 100%;
        transition-property: transform;
        font-stretch: normal;
        font-style: normal;

        @include respond-above(l) {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &:not(:first-child) {
                margin-top: rem(17px);
            }
        }

        &:not(:last-child) {
            margin-right: rem(12px);
            @include respond-above(l) {
                margin-right: 0;
            }
        }

        p {
            margin-bottom: 0;
        }

        &--category {
            font-size: rem(12px);
            font-weight: 500;
            line-height: 1.5;
            letter-spacing: normal;
        }

        &--title {
            font-size: rem(16px);
            font-weight: 600;
            line-height: 1.38;
            letter-spacing: rem(0.2px);
            text-decoration: none;
            color: #000000;
            margin-top: rem(5px);

            @include respond-above(l) {
                margin-top: rem(9px);
            }
        }

        &--description {
            font-size: rem(13px);
            font-weight: normal;
            line-height: rem(21px);
            letter-spacing: rem(0.19px);
            color: var(--color-brillant-grey);
            margin-top: rem(5px);
            flex-grow: 1;

            @include respond-above(l) {
                margin-top: rem(9px);
            }
        }
    }

    &__slideBottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: rem(18px);
        margin-bottom: rem(15px);

        @include respond-above(l) {
            margin-top: rem(26px);
            margin-bottom: rem(36px);
        }

        &--link {
            font-size: rem(12px);
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-decoration: underline;
            color: #f7c260;

            @include respond-above(l) {
                display: none;
            }
        }

        &--cta {
            display: flex;
            justify-content: flex-start;
            position: relative;
        }

        &--ctaIcon:hover {
            cursor: pointer;
        }
        &--ctaIcon:not(:last-child) {
            margin-right: rem(20px);
        }
    }

    &__shareContent {
        position: absolute;
        top: rem(21px);
        left: rem(-162px);

        @include respond-above(l) {
            top: rem(-21px);
            left: rem(16px);
        }
    }

    &__carousel {
        max-width: rem(246px) + rem(12px);
        margin-top: rem(23px);

        @include respond-above(l) {
            max-width: max-content;
        }
    }

    &__track {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        transition-property: transform;

        @include respond-above(l) {
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &__pagination {
        margin-top: rem(62px);
        display: flex;
        justify-content: center;
        /* margin-right needed for adjusting centering */
        margin-right: rem($container-wpad);

        @include respond-above(l) {
            display: none;
        }
    }

    &__bullet {
        width: rem(10px);
        height: rem(10px);
        border-radius: 50%;
        opacity: 0.39;
        background-color: rgba(76, 58, 51, 0.5);
        &:not(:last-child) {
            margin-right: rem(10px);
        }
        &--active {
            opacity: 1;
            background-color: #4c3a33;
        }
    }

    /* st12-picture fix on mobile */
    &__slideContainer .st12-picture--cover,
    &__slideContainer .st12-picture--contain {
        @include respond-below(l) {
            max-width: 246px;
            max-height: 166px;
        }
    }
}
