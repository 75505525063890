@use '../../../../../../../style/modules/functions' as *;
@use '../../../../../../../style/modules/commons' as *;
@use '../../../../../../../style/modules/variables' as *;
@use '../../../../../../../style/modules/mixins' as *;

.akt-recipes-box-cta {
    margin-top: rem(68px);

    @include respond-above(l) {
        margin-top: rem(30px);
        margin-bottom: rem(67px);
    }

    &__title {
        
        font-size: rem(20px);
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: normal;
    }

    &__list {
        list-style: none;
        padding-inline-start: 0;
        margin-bottom: 0;
        margin-top: rem(9px);

        &--item {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &:not(:first-child) {
                margin-top: rem(8px);
            }
        }
    }

    &__text {
        margin-left: rem(17px);

        &--link:hover .akt-recipes-box-cta__text--title {
            color: #f7c260;
        }

        &--support {
            @extend %font-display;
            font-size: rem(16px);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.13;
            letter-spacing: normal;
        }

        &--title {
            
            font-size: rem(14px);
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
            margin-top: rem(4px);
            transition: color 0.2s ease-in-out;
        }

        p {
            margin-bottom: 0;
        }

        a {
            text-decoration: none;
            color: var(--color-black);
        }
    }

    &__iconBg {
        background: url('../../../../../../../resources/saporie/images/dot_icon.svg');
        background-position: top center;
        background-size: rem(90px) rem(90px);
    }
}
