@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.st12-picture {
    position: relative;
    display: block;
    overflow: hidden;

    &__img {
        display: block;
        width: 100%;
        min-height: 100%;
        min-width: 100%;
    }

    &--lazy &__img {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        &--loaded {
            opacity: 1;
        }
    }

    &--lazy {
        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            height: 100%;
            background-color: var(--color-steamy-smoke);
            transition: opacity 0.3s ease-in-out;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            height: 100%;
            transform: translateX(-100%);
            background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
            animation: loading 1.5s infinite;
        }
    }

    &--loaded {
        &::before {
            opacity: 0;
        }
        &::after {
            display: none;
        }
    }

    &--cover,
    &--contain,
    &--scaledown {
        overflow: hidden;
        height: 100%;
        width: 100%;

        & > img {
            position: relative;
            object-position: center center;
            width: 100%;
            height: 100%;
        }
    }

    &--cover > img {
        object-fit: cover;
    }

    &--contain > img {
        object-fit: contain;
    }

    &--scaledown > img {
        object-fit: scale-down;
    }
}
