@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.st14-product {
    width: rem(272px);
    min-height: rem(80px);
    
    &__pic {
        display: inline-block;
        overflow: hidden;
        border-radius: 100%;
        width: rem(80px);
        height: rem(80px);
    }
    
    &__media {
        margin-right: rem(10px);
    }

    &__container {
        display: flex;
        flex-direction: row;
        align-items: center;

    }

    &__texts {
        display: flex;
        flex-direction: column;
        color: var(--color-black);
        text-decoration: none;
        padding-top: rem(15px);
    }

    &__title {
        @extend %font-h6;
        font-size: rem(14px);
        font-weight: 500;
        text-align: left;
    }

    &__category {
        @extend %font-label;
        font-weight: 600;

        &--chef {
            @extend %font-display;
            font-size: rem(16px);
            font-weight: normal;
        }
    }
}
